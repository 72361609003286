import {Component, Inject, OnInit, Input } from '@angular/core';

@Component({
    selector: 'results-box-in-data',
    templateUrl: 'angular-blocworx/components/fields/results-box/templates/in-data.html',
    styleUrls: ['angular-blocworx/components/fields/results-box/results-box.component.scss'],
})

export class ResultsBoxInData implements OnInit {

    @Input() field;
    @Input() scanStation;
    @Input() row;
    @Input() main;
    @Input() data;
    @Input() fc;

    ngOnInit(): void {

    }

}