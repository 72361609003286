import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TextFieldServiceBLCXAU {
    constructor() {
    }

    getInitialParams() {

        return {
            'upperLowerCase': {
                'validation': {
                    'required': true,
                    'validationResponse': 'Please choose whether to you want mixed case or uppercase for this field',
                    'requiredType': 'stringMustNotBeEmpty'
                }
            },
            'defaultFieldValue': {
                'validation': {
                    'required': false
                }
            }
        }


    }

}