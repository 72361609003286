import {Component, Inject, OnInit, Input} from '@angular/core';


@Component({
    selector: 'text-field-create-edit-radio-button',
    templateUrl: 'angular-blocworx/components/fields/text/templates/create-edit/radio-button.html',
    styleUrls: ['angular-blocworx/components/fields/text/text.component.scss'],
})

export class TextFieldCreateEditRadioButton implements OnInit {

    @Input() fc;
    @Input() scanStation;

    ngOnInit(): void {

    }
}