import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router, NavigationStart} from '@angular/router';
import {
    CalendarResourcePlanningService
} from "@/angular-blocworx/components/fields/calendar-resource-planning/calendar-resource-planning.service";
import * as moment from 'moment';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';


@Component({
    selector: 'add-new-record-popup',
    templateUrl: 'angular-blocworx/components/fields/calendar-resource-planning/templates/edit-record.component.html'
})
export class EditRecordComponent {

    startDate: Date;
    endDate: Date;
    selectedMinute: number;
    defaultStartTime: string; // to store the formatted time string
    defaultEndTime: string;
    pushToLiveAttempted: boolean
    isLoading: boolean
    editRecordLabel: string
    times: string[] = [];
    isHandset: boolean;
    endFormattedDate: string;
    colours: string[] = ["red", "sherpa-blue", "orange", "green", "blue", "yellow", "cyan", "purple"];

    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<EditRecordComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        @Inject(MatSnackBar) private snackBar: MatSnackBar,
        @Inject(Router) private router: Router,
        @Inject(CalendarResourcePlanningService) private calendarService: CalendarResourcePlanningService,
        @Inject(BreakpointObserver) private breakpointObserver: BreakpointObserver
    ) {

        // Listen for route changes
        this.router.events.subscribe((event) => {
            // Check if the event is an instance of NavigationStart
            if (event instanceof NavigationStart) {
                this.closeDialog();
            }
        });

        for (let i = 0; i < 24; i++) {
            for (let j = 0; j < 60; j += 15) {
                const hour = i < 10 ? '0' + i : '' + i;
                const minute = j < 10 ? '0' + j : '' + j;
                this.times.push(`${hour}:${minute}`);
            }
        }

        this.breakpointObserver.observe([
            Breakpoints.Handset
        ]).subscribe(result => {
            this.isHandset = result.matches;
        });
    }

    onDateChange(event, startOrEndDate) {
        if (startOrEndDate == 'startDate') {
            let newDate = event.value;
            const timeParts = this.defaultStartTime.split(':');

            let formattedDate = moment(newDate).format('YYYY-MM-DD');
            this.data.formattedDate = `${formattedDate} ${timeParts[0]}:${timeParts[1]}:00`;
        } else {
            let endDate = event.value;
            const timeParts = this.defaultEndTime.split(':');

            let formattedEndDate = moment(endDate).format('YYYY-MM-DD');
            this.endFormattedDate = `${formattedEndDate} ${timeParts[0]}:${timeParts[1]}:00`;
        }

    }

    onTimeChange(event, startOrEnd) {

        if (startOrEnd == 'startTime') {
            let newDate = this.startDate;
            let formattedDate = moment(newDate).format('YYYY-MM-DD');
            // Adjust the time part based on user input
            const timeParts = this.defaultStartTime.split(':');
            this.data.formattedDate = `${formattedDate} ${timeParts[0]}:${timeParts[1]}:00`;
        } else {
            let endDate = this.endDate;
            let formattedEndDate = moment(endDate).format('YYYY-MM-DD');
            const timeParts = this.defaultEndTime.split(':');
            this.endFormattedDate = `${formattedEndDate} ${timeParts[0]}:${timeParts[1]}:00`;
        }


    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    get isMobile(): boolean {
        return this.isHandset;
    }

    ngOnInit() {
        // Parse the formattedDate passed in
        const initMoment = moment(this.data.item.blcx_start_datetime_formatted_value);

        this.startDate = initMoment.toDate();

        let formattedDate = moment(this.startDate).format('YYYY-MM-DD');

        // Format the time
        let hour = initMoment.format('HH');
        const minute = initMoment.format('mm');
        this.defaultStartTime = `${hour}:${minute}`;

        this.data.formattedDate = `${formattedDate} ${hour}:${minute}:00`;

        this.defaultEndTime = `${hour}:${minute}`;

        let endMoment = moment(this.data.item.blcx_end_datetime_formatted_value);
        this.endDate = endMoment.toDate();
        let endHour = endMoment.format('HH');
        const endMinute = endMoment.format('mm');
        this.defaultEndTime = `${endHour}:${endMinute}`

        let endFormattedDate = moment(this.endDate).format('YYYY-MM-DD');
        this.endFormattedDate = `${endFormattedDate} ${this.defaultEndTime}:00`;

        let labelFieldSlug = this.data.linkedBlocFieldSlugs[this.data.fieldId].calendarItemLabelField;
        this.editRecordLabel = this.data.item[labelFieldSlug];

    }

    /**
     * This is the main function that triggers the push from development database no-code data to live
     * It calls a service which subsequently calls the laravel backend
     * @private
     */
    private async editRecord(): Promise<boolean> {

        this.isLoading = true;
        this.pushToLiveAttempted = true;

        try {
            // update the colour based on the select
            if(this.data.linkedBlocFieldSlugs[this.data.fieldId]['calendarRecordColourField'] != null && this.data.item[this.data.linkedBlocFieldSlugs[this.data.fieldId]['calendarRecordColourField']] != null) {
                this.data.extraDataToSend[this.data.linkedBlocFieldSlugs[this.data.fieldId]['calendarRecordColourField']] = this.data.item[this.data.linkedBlocFieldSlugs[this.data.fieldId]['calendarRecordColourField']];
            }

            let calendarResourcePlanningViewType: any = false;

            if(this.data.field.showSingleDayWithHoursViewOnDateClick == 1 && this.data.field.calendarResourcePlanningViewType == 'single-day-with-hours') {
                calendarResourcePlanningViewType = "single-day-with-hours";
            }

            await this.calendarService.addNewOrUpdateRecordFromCalendar(this.data.fieldId, this.data.formattedDate, this.editRecordLabel, this.data.leftValue, this.data.extraDataToSend, this.endFormattedDate, this.data.item.cartolytics_entry_row_id ,calendarResourcePlanningViewType);
            this.closeDialog();
        } catch (e) {
            console.log(e)
        }

        this.isLoading = false;


        return true;

    }

    private async deleteRecord() {
        const confirmation = window.confirm('Are you sure you want to delete this record? This cannot be undone.');
        if (confirmation) {
            await this.calendarService.deleteRecord(this.data.item.cartolytics_entry_row_id);
            this.closeDialog();
        }
    }
}