import {Directive, Inject, ElementRef, AfterContentInit, HostListener  } from '@angular/core';

@Directive({
    selector:'[nextOrPreviousField]'
})

export class NextOrPreviousFieldDirective{

    constructor( @Inject(ElementRef) private elementRef: ElementRef ){

    }

    @HostListener('keydown', ['$event'])
    onKeyDown(e){
        let code = e.keyCode || e.which;
        if (code === 13 || code === 9) {
            e.preventDefault();

            let pageElements = document.querySelectorAll(
                'input:not([disabled]):not([type="hidden"]):not([type="file"]):not(.ignore-as-tab), select, textarea,  .multi-button-container, .can-tab-to'),
                elem = e.target,
                focusNext = false,
                len = pageElements.length;


            if(!e.shiftKey) {

                for (let i = 0; i < len; i++) {
                    let pe = <HTMLElement>pageElements[i];

                    if (focusNext) {
                        if (pe.style.display !== 'none') {
                            pe.focus();
                            break;
                        }
                    } else if (pe === e.target) {
                        focusNext = true;
                    }
                }
            } else {
                for (let i = len-1; i > 0; i--) {
                    let pe = <HTMLElement>pageElements[i-1];
                    if (focusNext) {
                        if (pe.style.display !== 'none') {
                            pe.focus();
                            break;
                        }
                    } else if (pe === e.target) {
                        focusNext = true;
                    }
                }
            }
        }
    }

    ngAfterContentInit(){
        /*
        This is why we can access the element that is using the directive
        console.log(this.elementRef.nativeElement);
        */
    }
}