import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import {VersionControlService} from "@/angular-blocworx/components/admin/version-control/version-control.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationStart } from '@angular/router';

@Component({
    selector: 'proceed-with-push-to-live-popup',
    templateUrl: 'angular-blocworx/components/admin/version-control/proceed-with-push-to-live-popup.component.html',
})
export class ProceedWithPushToLivePopupComponent {

    pushToLiveSuccess: boolean
    pushToLiveAttempted: boolean
    isLoading: boolean
    constructor(
        @Inject(VersionControlService) private versionControlService: VersionControlService,
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<ProceedWithPushToLivePopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        @Inject(MatSnackBar) private snackBar: MatSnackBar,
        @Inject(Router) private router: Router
    ) {

        // Listen for route changes
        this.router.events.subscribe((event) => {
            // Check if the event is an instance of NavigationStart
            if (event instanceof NavigationStart) {
                this.closeDialog();
            }
        });
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    /**
     * This is the main function that triggers the push from development database no-code data to live
     * It calls a service which subsequently calls the laravel backend
     * @private
     */
    private async pushDevelopmentBuildToLiveBuild(): Promise<boolean> {

        this.isLoading = true;
        this.pushToLiveAttempted = true;

        try {
            let pushedToLiveResponse = await this.versionControlService.pushDevelopmentBuildToLiveBuild();

            if (pushedToLiveResponse.data == "Success") {
                this.pushToLiveSuccess = true;
                this.snackBar.open(this.data.main.languages.translatedWords['push_to_live_was_a_success'], 'Close', {
                    duration: 10000,
                    panelClass: ['snackbar-success']
                });
            } else {
                this.pushToLiveSuccess = false;
                this.snackBar.open(this.data.main.languages.translatedWords['push_to_live_was_a_failure'], 'Close', {
                    duration: 10000,
                    panelClass: ['snackbar-error']
                });
            }
        } catch (e) {
            this.pushToLiveSuccess = false;
            this.snackBar.open(this.data.main.languages.translatedWords['push_to_live_was_a_failure'], 'Close', {
                duration: 3000, // how long the snackbar will be displayed. You can adjust or remove.
                panelClass: ['snackbar-error'] // Custom class for styling if needed.
            });
        }

        this.isLoading = false;



        return true;

    }
}