import {Component, Inject, OnInit, Input } from '@angular/core';

@Component({
    selector: 'text-field-in-data',
    templateUrl: 'angular-blocworx/components/fields/text/templates/in-data.html',
    styleUrls: ['angular-blocworx/components/fields/text/text.component.scss'],
})

export class TextFieldInData implements OnInit {

    @Input() field;
    @Input() scanStation;
    @Input() row;
    @Input() main;
    @Input() data;
    @Input() fc;

    ngOnInit(): void {

    }

}