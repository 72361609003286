import {Component, Inject, OnInit, Input} from '@angular/core';


@Component({
    selector: 'results-box-create-edit-radio-button',
    templateUrl: 'angular-blocworx/components/fields/results-box/templates/create-edit/radio-button.html',
    styleUrls: ['angular-blocworx/components/fields/results-box/results-box.component.scss'],
})

export class ResultsBoxCreateEditRadioButton implements OnInit {

    @Input() fc;
    @Input() scanStation;

    ngOnInit(): void {

    }

    setFieldPopUp(activeField) {
        this.fc.initialFieldParams={};
        this.scanStation.activeField = activeField;
    }
}