import {Injectable, Inject} from '@angular/core';
import {Configuration} from "@/angular-blocworx/components/configuration/services/configuration.service";
import {RequestService} from "@/angular-blocworx/components/configuration/services/request.service";
import {HttpClient, HttpParams} from '@angular/common/http';
import {lastValueFrom} from 'rxjs';
import angular from "angular";

@Injectable({
    providedIn: 'root'
})

export class ManageAndBuildTemplatesService {
    apiUrl: string;
    evervaultEnabledApiUrl: string;

    constructor(@Inject(HttpClient) private http: HttpClient,
                @Inject(Configuration) private configuration: Configuration,
                @Inject(RequestService) private request: RequestService) {

        this.apiUrl = this.configuration.getApiUrl();
        this.evervaultEnabledApiUrl = this.configuration.getEvervaultInboundRelayUrl();
    }


}