import {Inject, Injectable} from '@angular/core';
import * as angular from "angular";
import {BaseFieldService} from "@/angular-blocworx/components/fields/BaseFieldService";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Configuration} from "@/angular-blocworx/components/configuration/services/configuration.service";
import {RequestService} from "@/angular-blocworx/components/configuration/services/request.service";
import {DataFiltering} from "@/legacy/controllers/DataFiltering.interface";
import {lastValueFrom} from "rxjs";
import {BehaviorSubject, Observable} from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class ResultsBoxService extends BaseFieldService {

    private triggerResultsBoxFromFieldActions: Function;
    private triggerResultsBoxViaForcedRefreshList: Function;
    private clearResultsBox: Function;
    private currentEntries: any = {};
    private currentColumnOrders: [];
    private currentTableColumns: any = {};

    private entriesSubject = new BehaviorSubject<any>(null);
    private columnOrdersSubject = new BehaviorSubject<any>(null);
    private tableColumnsSubject = new BehaviorSubject<any>(null);

    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(Configuration) protected configuration: Configuration,
        @Inject(RequestService) protected request: RequestService
    ) {
        super(http, configuration, request);
    }

    getInitialParams() {

        return {
            'resultsBoxStationAndFields': {
                'subParameters': {
                    'stationId': {
                        'validation': {
                            'required': true,
                            'validationResponse': 'Please choose a Bloc to look up',
                            'requiredType': 'stringMustNotBeEmpty'
                        }
                    },
                    'localFieldId': {
                        'validation': {
                            'required': false
                        }
                    },
                    'remoteFieldId': {
                        'validation': {
                            'required': false
                        }
                    }

                },
                'validation': {
                    'required': true,
                    'validationResponse': 'Please select a bloc and a field to look up',
                    'requiredType': 'listMustHaveOne'
                }
            },
            'dropdownFromStationReverseOrder': {
                'validation': {
                    'required': false
                }
            },
            'onlyShowFieldArray': {
                'validation': {
                    'required': false
                }
            },
            'valueBasedCellColours': {
                'validation': {
                    'required': false
                }
            },
            'dropdownMatchingDataLocalSelected': {
                'validation': {
                    'required': false
                }
            },
            'dropdownMatchingDataRemoteSelected': {
                'validation': {
                    'required': false
                }
            },
            'showDeleteIcon': {
                'validation': {
                    'required': false
                }
            },
            'allowValueEdits': {
                'validation': {
                    'required': false
                }
            },
            'resultsBoxEditorApplyBlocInitialisation': {
                'validation': {
                    'required': false
                }
            },
            'resultsBoxApplyFieldActions': {
                'validation': {
                    'required': false
                }
            },
            'resultsBoxPreSubmitFeatures': {
                'validation': {
                    'required': false
                }
            },
            'viewIconUrl': {
                'validation': {
                    'required': false
                }
            },
            'onlyApplyFieldActionsFieldArray': {
                'validation': {
                    'required': false
                }
            },
            'fieldsToRefreshAfterCompletion': {
                'validation': {
                    'required': false
                }
            },
            'openBlocToEditRecord': {
                'validation': {
                    'required': false
                }
            },
            'duplicateRecordLineItems': {
                'validation': {
                    'required': false
                }
            },
            'viewIconPopUp': {
                'validation': {
                    'required': false
                }
            },
            'resultsBoxReverseOrder': {
                'validation': {
                    'required': false
                }
            },
            localFieldMatchingFieldPairs: {
                'validation': {
                    'required': false
                }
            }
        }

    }


    /**
     * This function will be responsible for the building the colour/value combination
     *
     * @param valueBasedCellColours
     * @param value
     * @param colour
     */
    addValueColourCombination = (valueBasedCellColours, value, colour) => {

        if (value == null || colour == null) {
            alert('Please choose a value/colour combination');
            return valueBasedCellColours;
        }


        if (valueBasedCellColours == null || typeof valueBasedCellColours == 'undefined' || (typeof valueBasedCellColours != 'undefined' && valueBasedCellColours.length == 0) || (typeof valueBasedCellColours === 'object' && Object.keys(valueBasedCellColours).length == 0)) {
            valueBasedCellColours = [];
        }

        // rebuild to an array if its an object
        if (typeof valueBasedCellColours === 'object' && Object.keys(valueBasedCellColours).length > 0) {
            let temporaryArray = [];


            for (let i = 0; i < Object.keys(valueBasedCellColours).length; i++) {
                temporaryArray.push(valueBasedCellColours[i]);
            }


            valueBasedCellColours = temporaryArray;
        }

        valueBasedCellColours.push({
            'value': value,
            'colour': colour
        });

        return valueBasedCellColours;
    }

    /**
     * This will be responsible to remove value colour combinations
     *
     * @param valueBasedCellColours
     * @param index
     */
    removeValueColourCombination = (valueBasedCellColours, index = null) => {


        let temporaryArray = [];

        let i = 0;
        for (let j = 0; j < Object.keys(valueBasedCellColours).length; j++) {
            if (j != index) {
                console.log(valueBasedCellColours[j])
                temporaryArray[i] = valueBasedCellColours[j];
                i++;
            }
        }

        valueBasedCellColours = temporaryArray;


        return valueBasedCellColours;
    }

    /**
     * This will be responsible to remove from the list of fields that we are only showing
     *
     * @param onlyShowFieldArray
     * @param index
     */
    removeResultsBoxOnlyShowField = (onlyShowFieldArray, index = null) => {

        if (index == null) {
            alert('Please Select a field');
            return onlyShowFieldArray;
        }

        delete onlyShowFieldArray[index];

        // build new object with fresh keys to keep it in sync
        let fieldListForLoop = onlyShowFieldArray;
        onlyShowFieldArray = [];
        let i = 0;

        for (let i = 0; i < fieldListForLoop.length; i++) {
            onlyShowFieldArray[i] = fieldListForLoop[i];
        }

        return onlyShowFieldArray;
    }


    getDataForDataFiltering = async (dataFilteringData, fieldIdOrFieldSlug): Promise<any> => {

        let apiBase: string = this.evervaultEnabledApiUrl + 'get-data-for-data-filtering';

        let dataFilteringDataString = JSON.stringify(dataFilteringData);

        let params = new HttpParams()
            .set('dataFilteringData', dataFilteringDataString)
            .set('fieldIdOrFieldSlug', fieldIdOrFieldSlug)
            .set('resultLimit', dataFilteringData.resultLimit);

        let headers = this.request.getHeaders();

        const response = this.http.get(apiBase, {params, headers});

        return lastValueFrom(response);
    }


    public getScanStationFields = (scanStationID): Promise<any> => {
        const headers = this.request.getHeaders()

        const body = {
            scanStationID,
        }
        const response = this.http.post(`${this.apiUrl}get-scan-station-fields`, body, {headers});
        return lastValueFrom(response)
    }


    public triggerFieldActionsForSingleRecordInResultsBox = (resultsBoxFieldData, saveData, field): Promise<any> => {
        const headers = this.request.getHeaders()

        const body = {
            resultsBoxFieldData: resultsBoxFieldData,
            saveData: saveData,
            resultsBoxEditorApplyBlocInitialisation: field.resultsBoxEditorApplyBlocInitialisation,
            resultsBoxApplyFieldActions: field.resultsBoxApplyFieldActions,
            resultsBoxPreSubmitFeatures: field.resultsBoxPreSubmitFeatures,
            triggerSourcefieldId: field.id
        }
        const response = this.http.post(`${this.apiUrl}trigger-field-actions-for-single-record-in-results-box`, body, {headers});
        return lastValueFrom(response)
    }

    getDataFilteringDataForResultsBox = (parseResults, scanStationID, filteringData = '', scanStationFieldIdsToField, dataToAdd) => {
        try {

            if (parseResults == null) {
                return null;
            }

            let dataFilteringData = <DataFiltering>{};
            dataFilteringData.stationID = parseResults.scanStationID;
            dataFilteringData.resultLimit = 500;
            dataFilteringData.currentPage = 1;
            dataFilteringData.filterDataToQuery = {};

            if (parseResults.localFieldMatchingFieldPairs != null && Object.keys(parseResults.localFieldMatchingFieldPairs).length > 0) {

                let fieldMatchingPairsLength = Object.keys(parseResults.localFieldMatchingFieldPairs).length;

                for (let i = 0; i < fieldMatchingPairsLength; i++) {
                    dataFilteringData.filterDataToQuery[parseResults.localFieldMatchingFieldPairs[i].remoteFieldId] = [dataToAdd[scanStationFieldIdsToField[parseResults.localFieldMatchingFieldPairs[i].localFieldId].field_slug]];
                }

            } else {
                dataFilteringData.filterDataToQuery[parseResults.resultsBoxRemoteFieldId] = [filteringData];
            }

            dataFilteringData.currentStationID = scanStationID;
            dataFilteringData.reverse = parseResults.resultsBoxReverseOption;

            if (parseResults.sortByFieldId != null) {
                dataFilteringData.sortByFieldId = parseResults.sortByFieldId;
            }

            return dataFilteringData;

        } catch (e) {
            console.log(e)
        }
    }


    /**
     * These functions allow us to call functions in the parent component, this means they can be called
     * from any other component or angularjs controller
     * @param fn
     */
    public setTriggerResultsBoxFromFieldActions(fn: Function) {
        this.triggerResultsBoxFromFieldActions = fn;
    }

    public callTriggerResultsBoxFromFieldActions(fieldId) {
        if (this.triggerResultsBoxFromFieldActions) {
            return this.triggerResultsBoxFromFieldActions(fieldId);
        }
    }

    public setTriggerResultsBoxViaForcedRefreshList(fn: Function) {
        this.triggerResultsBoxViaForcedRefreshList = fn;
    }

    public callTriggerResultsBoxViaForcedRefreshList(fieldsToRefreshAfterCompletion) {
        if (this.triggerResultsBoxViaForcedRefreshList) {
            return this.triggerResultsBoxViaForcedRefreshList(fieldsToRefreshAfterCompletion);
        }
    }

    public setTriggerClearResultsBox(fn: Function) {
        this.clearResultsBox = fn;
    }

    public callTriggerClearResultsBox(fieldSlug) {
        if (this.clearResultsBox) {
            return this.clearResultsBox(fieldSlug);
        }
    }

    get entries$() {
        return this.entriesSubject.asObservable();
    }

    updateEntries(newData: any) {
        this.currentEntries = newData;
        this.entriesSubject.next(newData);
    }

    get columnOrders$() {
        return this.columnOrdersSubject.asObservable();
    }

    updateColumnOrders(newData: any) {
        this.currentColumnOrders = newData;
        this.columnOrdersSubject.next(newData);
    }

    get tableColumns$() {
        return this.tableColumnsSubject.asObservable();
    }

    updateTableColumns(newData: any) {
        this.currentTableColumns = newData;
        this.tableColumnsSubject.next(newData);
    }

    addCurrentResultsBoxFieldToRefreshList = (resultsBoxField) => {
        // if resultsBoxField.fieldsToRefreshAfterCompletion either doesnt exist or is an empty object or is just an object which only contains resultsBoxField.id then create an array and add resultsBoxField.id to it
        if (resultsBoxField.fieldsToRefreshAfterCompletion == null || Object.keys(resultsBoxField.fieldsToRefreshAfterCompletion).length == 0 || (Object.keys(resultsBoxField.fieldsToRefreshAfterCompletion).length == 1 && resultsBoxField.fieldsToRefreshAfterCompletion[resultsBoxField.id] != null)) {
            resultsBoxField.fieldsToRefreshAfterCompletion = [resultsBoxField.id];
        }
        // else if it does not contain resultsBoxField.id then add it to the array, but if its an object firstly convert it to an array
        else if (resultsBoxField.fieldsToRefreshAfterCompletion[resultsBoxField.id] == null) {
            if (!Array.isArray(resultsBoxField.fieldsToRefreshAfterCompletion)) {
                resultsBoxField.fieldsToRefreshAfterCompletion = Object.values(resultsBoxField.fieldsToRefreshAfterCompletion);
            }
            resultsBoxField.fieldsToRefreshAfterCompletion.push(resultsBoxField.id);

        }

        return resultsBoxField;
    }

    getAllCurrentResultsBoxColumnOrders() {
        return this.currentColumnOrders;

    }


    getAllCurrentResultsBoxTableColumns() {
        return this.currentTableColumns;

    }

    getAllCurrentResultsBoxData() {
        return {
            'entries': this.currentEntries,
            'columnOrders': this.currentColumnOrders,
            'tableColumns': this.currentTableColumns
        }

    }

    restoreAllCurrentResultsBoxData(resultsBoxData: any) {
        this.updateEntries(resultsBoxData.entries);
        this.updateColumnOrders(resultsBoxData.columnOrders);
        this.updateTableColumns(resultsBoxData.tableColumns);
    }
}