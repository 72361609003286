import {Component, OnInit, Injectable, Optional, Inject, Input, Injector, SimpleChanges} from '@angular/core';
import {VersionControlService} from "@/angular-blocworx/components/admin/version-control/version-control.service";
import {MatDialog} from "@angular/material/dialog";
import { OverlayContainer } from '@angular/cdk/overlay';
import { ProceedWithPushToLivePopupComponent} from "@/angular-blocworx/components/admin/version-control/proceed-with-push-to-live-popup.component";

@Component({
    selector: 'version-control',
    templateUrl: 'angular-blocworx/components/admin/version-control/version-control.html',
    styleUrls: ['angular-blocworx/components/admin/version-control/version-control.scss']
})

@Injectable({
    providedIn: 'root'
})

export class VersionControl implements OnInit {

    @Input() main;
    liveVersionDomain: string
    isVersionControlAvailable: boolean


    constructor(
        @Inject(VersionControlService) private versionControlService: VersionControlService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(Injector) private injector: Injector,
        @Inject(OverlayContainer) private overlayContainer: OverlayContainer) {

    }

    ngOnInit() {

    }

    async ngAfterViewInit() {
        await this.getLiveVersionDomain();
    }

    /**
     * Here we get the live version domain, this is so the user can clearly see where they are pushing their
     * development version to. It should be obvious to them, they will generally only have one live domain but
     * this helps remove ambiguity
     * @private
     */
    private async getLiveVersionDomain(): Promise<boolean> {

        let liveVersionDomainResponse = await this.versionControlService.getLiveVersionDomain();

        if (liveVersionDomainResponse.data == "None Found") {
            this.liveVersionDomain = this.main.languages.translatedWords['not_set_up_for_version_control'];
            this.isVersionControlAvailable = false;
        } else {
            this.liveVersionDomain = liveVersionDomainResponse.data;
            this.isVersionControlAvailable = true;
        }

        return true;

    }

    /**
     * This opens up a pop up to prepare the push to live
     */
    openProceedWithPushToLivePopup(): void {
        const dialogRef = this.dialog.open(ProceedWithPushToLivePopupComponent, {
            data: {
                main: this.main
            }
        });

        this.overlayContainer.getContainerElement().classList.add('blocworx-popup');

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
            this.overlayContainer.getContainerElement().classList.remove('blocworx-popup');
        });
    }


}
