import {
    Component, Inject,  Input
} from '@angular/core';

import {AppSettingService} from "@/angular-blocworx/components/admin/app-setting/app-setting.service";

@Component({
    selector: 'evervault-app-setting',
    templateUrl: 'angular-blocworx/components/admin/app-setting/evervault/evervault-app-setting.html',
})

export class EvervaultAppSetting {

    @Input() main;

    constructor(
        @Inject(AppSettingService) private appSettingService: AppSettingService,
    ) {

    }

    evervaultUrl: string = '';
    evervaultFieldSlugs: string = ''
    evervaultBasicToken: string = ''

    ngOnInit(): void {
    }

    async ngAfterViewInit() {

        this.evervaultUrl = this.appSettingService.loadSingleAppSetting('evervault_url');
        this.evervaultFieldSlugs = this.appSettingService.loadSingleAppSetting('evervault_field_slugs');
        this.evervaultBasicToken = this.appSettingService.loadSingleAppSetting('evervault_basic_token');

    }

    async setAppSetting(settingKey, settingValue) {
        await this.appSettingService.setAppSetting(this.main.cartolyticsCustomerID, settingKey, settingValue);
    }

}
