import {Injectable, Inject} from '@angular/core';
import {Configuration} from "@/angular-blocworx/components/configuration/services/configuration.service";
import {RequestService} from "@/angular-blocworx/components/configuration/services/request.service";
import {HttpClient, HttpParams} from '@angular/common/http';
import {lastValueFrom} from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class AutocompleteSearchService {
    apiUrl: string;
    evervaultEnabledApiUrl: string;

    constructor(@Inject(HttpClient) private http: HttpClient,
                @Inject(Configuration) private configuration: Configuration,
                @Inject(RequestService) private request: RequestService) {

        this.apiUrl = this.configuration.getApiUrl();
        this.evervaultEnabledApiUrl = this.configuration.getEvervaultInboundRelayUrl();
    }

    /**
     * This function calls the API to look up values based on what the  person is typing
     * It is using a standard Blocworx API call get-data-for-data-filtering
     * @param value
     * @returns {Promise<PushSubscription>}
     */
    public lookUpValueForAutoCompleteSearch = async (value, stationId, jobId, specificFieldSearchModel) : Promise<any> => {

        let headers = this.request.getHeaders();

        let dataFilteringData = {
            stationID: stationId,
            jobID: jobId,
            "filterDataToQuery":{[specificFieldSearchModel.field_slug]:[value]}
        };

        const params = new HttpParams().set('dataFilteringData', JSON.stringify(dataFilteringData))
            .set('resultLimit', 10)
            .set('searchPartOfString', 1)
            .set('uniqueFieldSlugToLookUp', specificFieldSearchModel.field_slug);

        let response = this.http.get(
            `${this.evervaultEnabledApiUrl}get-data-for-data-filtering`,
            {params, headers}
        );

        return lastValueFrom(response)

    }

}