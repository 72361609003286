import {Injectable, Inject} from '@angular/core';
import {Configuration} from "@/angular-blocworx/components/configuration/services/configuration.service";
import {RequestService} from "@/angular-blocworx/components/configuration/services/request.service";
import {HttpClient, HttpParams} from '@angular/common/http';
import {lastValueFrom} from 'rxjs';
import {AppSettingService} from "@/angular-blocworx/components/admin/app-setting/app-setting.service";
import { HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class EvervaultService {
    apiUrl: string;

    constructor(@Inject(HttpClient) private http: HttpClient,
                @Inject(Configuration) private configuration: Configuration,
                @Inject(RequestService) private request: RequestService,
                @Inject(AppSettingService) private appSettingService: AppSettingService) {

    }


    /**
     * This calls the Evervault API decrypt endpoint
     */
    public decrypt = async (data) : Promise<any> => {
        let evervaultBasicToken = this.appSettingService.loadSingleAppSetting('evervault_basic_token');


        // Create the Basic Authentication header
        let basicAuthHeader = `Basic ${evervaultBasicToken}`;

        // Add the Basic Authentication header to your existing headers
        const headers = new HttpHeaders().set('Authorization', basicAuthHeader)
            .set('Content-Type','application/json');

        const response = this.http.post(`https://api.evervault.com/decrypt`, data, {headers});

        return lastValueFrom(response);
    }



}