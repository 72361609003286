import {Component, Inject, OnInit, Input } from '@angular/core';

@Component({
    selector: 'calendar-resource-planning-in-data',
    templateUrl: 'angular-blocworx/components/fields/calendar-resource-planning/templates/in-data.html',
    styleUrls: ['angular-blocworx/components/fields/calendar-resource-planning/calendar-resource-planning.component.scss'],
})

export class CalendarResourcePlanningInData implements OnInit {

    @Input() field;
    @Input() scanStation;
    @Input() row;
    @Input() main;
    @Input() data;
    @Input() fc;

    ngOnInit(): void {

    }

}