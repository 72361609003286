import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationStart } from '@angular/router';
import {CalendarResourcePlanningService} from "@/angular-blocworx/components/fields/calendar-resource-planning/calendar-resource-planning.service";
import * as moment from 'moment';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';


@Component({
    selector: 'add-new-record-popup',
    templateUrl: 'angular-blocworx/components/fields/calendar-resource-planning/templates/add-new-record.component.html'
})
export class AddNewRecordComponent {

    startDate: Date;
    endDate: Date;
    selectedMinute: number;
    defaultStartTime: string; // to store the formatted time string
    defaultEndTime: string;
    pushToLiveAttempted: boolean
    isLoading: boolean
    newRecordValue: string
    times: string[] = [];
    isHandset: boolean;
    endFormattedDate: string;
    colours: string[] = ["red", "sherpa-blue", "orange", "green", "blue", "yellow", "cyan", "purple"];
    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<AddNewRecordComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        @Inject(MatSnackBar) private snackBar: MatSnackBar,
        @Inject(Router) private router: Router,
        @Inject(CalendarResourcePlanningService) private calendarService: CalendarResourcePlanningService,
        @Inject(BreakpointObserver) private breakpointObserver: BreakpointObserver

    ) {

        // Listen for route changes
        this.router.events.subscribe((event) => {
            // Check if the event is an instance of NavigationStart
            if (event instanceof NavigationStart) {
                this.closeDialog();
            }
        });

        for (let i = 0; i < 24; i++) {
            for (let j = 0; j < 60; j += 15) {
                const hour = i < 10 ? '0' + i : '' + i;
                const minute = j < 10 ? '0' + j : '' + j;
                this.times.push(`${hour}:${minute}`);
            }
        }

        this.breakpointObserver.observe([
            Breakpoints.Handset
        ]).subscribe(result => {
            this.isHandset = result.matches;
        });
    }

    onDateChange(event, startOrEndDate) {
        if(startOrEndDate == 'startDate') {
            let newDate = event.value;
            const timeParts = this.defaultStartTime.split(':');

            let formattedDate = moment(newDate).format('YYYY-MM-DD');
            this.data.formattedDate = `${formattedDate} ${timeParts[0]}:${timeParts[1]}:00`;
        } else {
            let endDate = event.value;
            const timeParts = this.defaultEndTime.split(':');

            let formattedEndDate = moment(endDate).format('YYYY-MM-DD');
            this.endFormattedDate = `${formattedEndDate} ${timeParts[0]}:${timeParts[1]}:00`;
        }

    }

    onTimeChange(event, startOrEnd) {

        if(startOrEnd == 'startTime') {
            let newDate = this.startDate;
            let formattedDate = moment(newDate).format('YYYY-MM-DD');
            // Adjust the time part based on user input
            const timeParts = this.defaultStartTime.split(':');
            this.data.formattedDate = `${formattedDate} ${timeParts[0]}:${timeParts[1]}:00`;
        } else {
            let endDate = this.endDate;
            let formattedEndDate = moment(endDate).format('YYYY-MM-DD');
            const timeParts = this.defaultEndTime.split(':');
            this.endFormattedDate = `${formattedEndDate} ${timeParts[0]}:${timeParts[1]}:00`;
        }



    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    get isMobile(): boolean {
        return this.isHandset;
    }

    ngOnInit() {
        // Parse the formattedDate passed in
        const initMoment = moment(this.data.formattedDate);

        this.startDate = initMoment.toDate();
        
        // Format the time
        let hour = initMoment.format('HH');
        let hourPlusOne = initMoment.add(1,'hours').format('HH');
        const minute = initMoment.format('mm');
        this.defaultStartTime = `${hour}:${minute}`;
        this.defaultEndTime = `${hour}:${minute}`;
        
        if(this.data.field.calendarResourcePlanningViewType == 'month-calendar-view' || this.data.field.calendarResourcePlanningViewType == 'resource-planning-view') {
            this.endDate = initMoment.add(1, 'days').toDate();
        } else {
            this.endDate = initMoment.add(1, 'hours').toDate();
            this.defaultEndTime = `${hourPlusOne}:${minute}`;
        }

        let endFormattedDate = moment(this.endDate).format('YYYY-MM-DD');
        this.endFormattedDate = `${endFormattedDate} ${this.defaultEndTime}:00`;

    }

    /**
     * This is the main function that triggers the push from development database no-code data to live
     * It calls a service which subsequently calls the laravel backend
     * @private
     */
    private async addNewRecord(): Promise<boolean> {

        this.isLoading = true;
        this.pushToLiveAttempted = true;

        try {
            let calendarResourcePlanningViewType: any = false;

            if(this.data.field.showSingleDayWithHoursViewOnDateClick == 1 && this.data.field.calendarResourcePlanningViewType == 'single-day-with-hours') {
                calendarResourcePlanningViewType = "single-day-with-hours";
            }

            await this.calendarService.addNewOrUpdateRecordFromCalendar(this.data.fieldId, this.data.formattedDate, this.newRecordValue, this.data.leftValue, this.data.extraDataToSend, this.endFormattedDate, null, calendarResourcePlanningViewType);
            this.closeDialog();
        } catch (e) {
            console.log(e)
        }

        this.isLoading = false;


        return true;

    }
}