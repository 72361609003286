import { Component, OnInit, Injectable, Optional, Inject, Input } from '@angular/core';
import {DataEditHistoryService} from "@/angular-blocworx/components/scanStation/data-edit-history/data-edit-history.service";

@Component({
    selector: 'something-else',
    templateUrl: 'angular-blocworx/components/scanStation/data-edit-history/data-edit-history.html'
})

@Injectable({
    providedIn: 'root'
})

export class DataEditHistory implements OnInit {

    @Input() scanStation;
    @Input() main;
    dataEditHistoryForSingleRecord: any;

    constructor(
        @Inject(DataEditHistoryService) private dataEditHistoryService: DataEditHistoryService,
    ) {}

    ngOnInit() {
    }

    getDataEditHistory = async () => {
        this.dataEditHistoryForSingleRecord = await this.dataEditHistoryService.getDataEditHistoryForSingleRecord(this.scanStation.scanStationObj.stationDataID);

    }

    showDataEditHistoryButton = () => {
        return (this.scanStation.scanStationObj.stationDataID != null && this.scanStation.scanStationObj.stationDetails.showDataEditHistory == 1)
    }

}
