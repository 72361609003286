import {Component, OnInit, Injectable} from '@angular/core';
import { HttpHeaders} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

@Component({"template":""})
export class RequestService implements OnInit {

    satellizerToken: string

    ngOnInit(): void {

    }

    constructor() {
        this.initializeToken();
        localStorage.setItem('appSettings', '{"data":[]}');
    }

    private initializeToken(): void {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');

        if (token) {
            localStorage.setItem('satellizer_token', token);
            this.satellizerToken = token;
        } else {
            this.satellizerToken = localStorage.getItem('satellizer_token');
        }
    }


    /**
     * This is the generic getHeaders function
     */
    public getHeaders = () => {

        return new HttpHeaders(
            {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.satellizerToken}`,
            });

    }

    /**
     * This will load all the headers for Blob type responses
     */

    public getBlobHeaders = () => {

        return new HttpHeaders(
            {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.satellizerToken}`,
                "responseType": "blob" as "json"
            });

    }
}

