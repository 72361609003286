import {Component, Inject, OnInit, ViewChildren, Input, ElementRef, QueryList } from '@angular/core';
import { RuleService } from '@/angular-blocworx/components/rule/service/rule.service'
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'text-field-in-form',
    templateUrl: 'angular-blocworx/components/fields/text/templates/in-form.html',
    styleUrls: ['angular-blocworx/components/fields/text/text.component.scss'],
    providers: [
        RuleService,
    ]
})

export class TextFieldInForm implements OnInit {

    @Input() field;
    @Input() scanStation;
    @ViewChildren('normalTextField') normalTextField: QueryList<ElementRef>;
    @ViewChildren('defaultvalue') defaultvalue: QueryList<ElementRef>;
    @ViewChildren('autocompleteTextField') autocompleteTextField: QueryList<ElementRef>;

    constructor(
        @Inject(RuleService) private ruleService: RuleService
    ) {}

    private keyDownSubject = new Subject<KeyboardEvent>()
    ngOnInit() {
        let debounceTimeMilliseconds = 0;
        if(this.field.triggerRuleOnValueChange == 1) {
            debounceTimeMilliseconds = 350;
        }
        this.keyDownSubject.pipe(
            debounceTime(debounceTimeMilliseconds)
        ).subscribe(async (event) => {
            await this.scanStation.getRules(event,this.field.id,this.field.special_field_key,this.scanStation.scanStationObj.dataToAdd[this.field.field_slug],this.field.fieldIndex,this.field.field_slug);

            // this will be cleaning a broken rule by using event to get the id of the field and check if contain broken-rule in it
            this.ruleService.cleanBrokenRule(event, this.scanStation.prompt);
        });
    }

    ngAfterViewInit() {

        if (this.normalTextField.length > 0) {
            this.scanStation.domIndexes[this.field.field_slug] = this.field.fieldIndex
        }

        // set a default value if we have one
        if (this.defaultvalue.length > 0) {
            this.scanStation.scanStationObj.dataToAdd[this.field.field_slug] = this.field.defaultFieldValue;
            this.setToUpperCase();
            this.scanStation.getRules('no-event', this.field.id, this.field.special_field_key, this.scanStation.scanStationObj.dataToAdd[this.field.field_slug], this.field.fieldIndex, this.field.field_slug);
        }


        // get data list if we are using the autocomplete option
        if (this.autocompleteTextField.length > 0) {
            this.scanStation.domIndexes[this.field.field_slug] = this.field.fieldIndex;
            this.scanStation.getStationDataForDropDown(this.field.field_slug, this.field.dropdownFromStationLookUp, this.field.dropdownFromStationOnlyDataAfterDate, null, this.field.dropdownFromStationReverseOrder, this.field.dropDownFromStationExcludeMatchingDataFromAnotherFieldData, null)
        }

    }

    // the text field keyDown function
    async keyDown(event: KeyboardEvent) {
        this.keyDownSubject.next(event);
    }

    // the text field keyUp function, mainly for handling autocomplete search list
    keyUp(event) {
        this.setToUpperCase();

        if(this.isAutoComplete()) {
            this.scanStation.getSearchData(this.field.field_slug,this.scanStation.scanStationObj.dataToAdd[this.field.field_slug])
        }

        this.scanStation.triggerBackgroundDataUpdate(this.scanStation.scanStationObj.dataToAdd,this.scanStation.scanStationObj.id,this.scanStation.scanStationObj.dataToAdd[this.field.field_slug])
    }

    // if we have the uppercase field parameter then set the text to uppercase
    setToUpperCase() {
        if(this.isUpperCase()) {
            if (this.scanStation.scanStationObj.dataToAdd[this.field.field_slug] != null) {
                let value = this.scanStation.scanStationObj.dataToAdd[this.field.field_slug];
                if (typeof value === 'string') {
                    this.scanStation.scanStationObj.dataToAdd[this.field.field_slug] = value.toUpperCase();
                }
            }

        }
    }

    // check if we have the uppercase parameter
    isUpperCase() {
        if(this.field.upperLowerCase == 'uppercase') {
            return true;
        } else {
            return false;
        }
    }

    // check if we are using autocomplete or normal text field
    isAutoComplete() {
        return ((this.field.field_type == 'text' || this.field.field_type == 'numeric') && this.field.autocomplete);
    }

    // check if we have any results to show, this will prevent undefined errors for this.scanStation.searchResult[this.field.field_slug]
    canShowSearchResults() {
        return (this.scanStation.searchResult[this.field.field_slug] != null && this.scanStation.searchResult[this.field.field_slug].length > 0 && this.scanStation.scanStationObj.dataToAdd[this.field.field_slug].length>0)
    }

    // reset the search, this happens when someone clicks the first option in the autocomplete which is the same value they are typing
    forgetSearch() {
        this.scanStation.searchResult[this.field.field_slug] = null;
        this.scanStation.triggerBackgroundDataUpdate(this.scanStation.scanStationObj.dataToAdd,this.scanStation.scanStationObj.id,this.scanStation.scanStationObj.dataToAdd[this.field.field_slug]);this.scanStation.getRules('no-event',this.field.id,this.field.special_field_key,this.scanStation.scanStationObj.dataToAdd[this.field.field_slug],this.field.fieldIndex,this.field.field_slug);
    }

    // this happens when they have clicked something in the search (that is not the first option)
    populateSearch(result) {
        this.scanStation.scanStationObj.dataToAdd[this.field.field_slug] = result;
        this.scanStation.searchResult[this.field.field_slug]=null;
        this.scanStation.triggerBackgroundDataUpdate(this.scanStation.scanStationObj.dataToAdd,this.scanStation.scanStationObj.id,this.scanStation.scanStationObj.dataToAdd[this.field.field_slug]);
        this.scanStation.getRules('no-event',this.field.id,this.field.special_field_key,result,this.field.fieldIndex,this.field.field_slug);
    }

}