import {Injectable, Inject} from '@angular/core';
import {Configuration} from "@/angular-blocworx/components/configuration/services/configuration.service";
import {RequestService} from "@/angular-blocworx/components/configuration/services/request.service";
import {HttpClient, HttpParams} from '@angular/common/http';
import {lastValueFrom} from 'rxjs';
import {AppSettingService} from "@/angular-blocworx/components/admin/app-setting/app-setting.service";
import { HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class DataEditHistoryService {
    apiUrl: string;
    evervaultEnabledApiUrl: string;

    constructor(@Inject(HttpClient) private http: HttpClient,
                @Inject(Configuration) private configuration: Configuration,
                @Inject(RequestService) private request: RequestService,
                @Inject(AppSettingService) private appSettingService: AppSettingService) {

        this.apiUrl = this.configuration.getApiUrl();
        this.evervaultEnabledApiUrl = this.configuration.getEvervaultInboundRelayUrl();

    }


    public getDataEditHistoryForSingleRecord = async (stationDataId) : Promise<any> => {

        let headers = this.request.getHeaders();

        const params = new HttpParams().set('stationDataId', stationDataId);

        let response = this.http.get(
            `${this.evervaultEnabledApiUrl}get-data-edit-history-for-single-record`,
            {params, headers}
        );

        return lastValueFrom(response)

    }
}