import {Component, Inject, OnInit, Input } from '@angular/core';

@Component({
    selector: 'text-field-create-edit-popup',
    templateUrl: 'angular-blocworx/components/fields/text/templates/create-edit/popup.html',
    styleUrls: ['angular-blocworx/components/fields/text/text.component.scss'],
})

export class TextFieldCreateEditPopup implements OnInit {

    @Input() fc;
    @Input() scanStation;

    ngOnInit(): void {

    }

    ngAfterViewInit() {
        this.scanStation.activeField = 'text'
    }

}