import {Component, OnInit, Injectable, Optional, Inject, Input} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable, debounceTime} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {
    ManageAndBuildTemplatesService
} from "@/angular-blocworx/components/BlocSettings/manage-and-build-templates/manage-and-build-templates.service";
import {FieldEditingService} from "@/angular-blocworx/components/fields/fieldEditing/field-editing.service";


@Component({
    selector: 'manage-and-build-templates',
    templateUrl: 'angular-blocworx/components/BlocSettings/manage-and-build-templates/manage-and-build-templates.html',
})

@Injectable({
    providedIn: 'root'
})

export class ManageAndBuildTemplatesComponent implements OnInit {

    @Input() scanStation;
    @Input() templateController;
    conditionType: string;
    selectFieldToCheck: string;
    conditionSelection: string;
    valueToCompare: string;
    conditionList: object = {};
    selectedTemplate: string;
    templateIdsToTemplateList: any = [];

    constructor(
        @Inject(ManageAndBuildTemplatesService) private manageAndBuildTemplatesService: ManageAndBuildTemplatesService,
        @Inject(FieldEditingService) private fieldEditingService: FieldEditingService) {
    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        this.createTemplateIdsToTemplateList();
        this.loadConditionsListFromScanStationParameters();
    }

    loadConditionsListFromScanStationParameters = () => {
        if(this.scanStation.scanStationObj.stationDetails.templateConditionsListForAutomaticallyChoosing != null){

            // convert this.scanStation.scanStationObj.stationDetails.templateConditionsListForAutomaticallyChoosing to an object if its a string

            if(typeof this.scanStation.scanStationObj.stationDetails.templateConditionsListForAutomaticallyChoosing === 'string'){
                this.conditionList = JSON.parse(this.scanStation.scanStationObj.stationDetails.templateConditionsListForAutomaticallyChoosing);
            } else {
                this.conditionList = this.scanStation.scanStationObj.stationDetails.templateConditionsListForAutomaticallyChoosing;
            }

        }
    }


    createTemplateIdsToTemplateList = () => {
        // loop through the templateController.templateFileList and create an array of templates where the the key is from the id from each item
        // and the value is the template object without using forEach
        for (let i = 0; i < this.templateController.templateFileList.length; i++) {
            this.templateIdsToTemplateList[this.templateController.templateFileList[i].id] = this.templateController.templateFileList[i];
        }

    }

    addCondition = (templateId, conditionType, fieldId, conditionSelection, valueToCompare) => {

        try {

            if (this.conditionList[templateId] == null) {
                this.conditionList[templateId] =
                    {
                        templateId: templateId,
                        conditions: [],
                        conditionType: conditionType
                    };
            }

            this.fieldEditingService.validateCondition(fieldId, conditionSelection, valueToCompare);

            this.conditionList[templateId].conditions = this.fieldEditingService.addCondition(this.conditionList[templateId].conditions, fieldId, conditionSelection, valueToCompare);

            this.scanStation.setScanStationParameter(this.scanStation.id,'templateConditionsListForAutomaticallyChoosing',  this.convertObjectToArray(this.conditionList));
        } catch (err) {
            alert(err);
        }
    }

    deleteTemplateConditions = (templateId) => {
        delete this.conditionList[templateId];
        this.scanStation.setScanStationParameter(this.scanStation.id,'templateConditionsListForAutomaticallyChoosing', this.convertObjectToArray(this.conditionList));

    }

    convertObjectToArray(object:object): any[] {
        if (object != null) {
            return Object.values(object);
        }
    }


}
