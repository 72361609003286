import {Configuration} from "@/angular-blocworx/components/configuration/services/configuration.service";
import {Inject} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {RequestService} from "@/angular-blocworx/components/configuration/services/request.service";
import {lastValueFrom} from "rxjs";


export abstract class BaseFieldService {
    protected apiUrl: string;
    protected evervaultEnabledApiUrl: string;

    protected constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(Configuration) protected configuration: Configuration,
        @Inject(RequestService) protected request: RequestService
    ) {
        this.configuration.ngOnInit();
        this.apiUrl = this.configuration.getApiUrl();
        this.evervaultEnabledApiUrl = this.configuration.getEvervaultInboundRelayUrl();
    }

    public initiateAllFieldParametersFromValues(initialFieldParams: object, defaultFieldParameterObject: object) {
        const keys: string[] = Object.keys(defaultFieldParameterObject);

        for (const key of keys) {

            if (initialFieldParams[key] == null) {
                if (defaultFieldParameterObject[key].subParameters == null) {
                    initialFieldParams[key] = "";
                } else {
                    initialFieldParams[key] = {};
                }
            }

            if (defaultFieldParameterObject[key].subParameters != null) {
                const subParameterKeys: string[] = Object.keys(defaultFieldParameterObject[key].subParameters);
                for (const subParameterKey of subParameterKeys) {
                    if (initialFieldParams[key][subParameterKey] == null) {
                        initialFieldParams[key][subParameterKey] = "";
                    }
                }
            }
        }

        return initialFieldParams;
    }


    public getScanStationFields = (scanStationID): Promise<any> => {
        const headers = this.request.getHeaders()

        const body = {
            scanStationID,
        }
        const response = this.http.post(`${this.apiUrl}get-scan-station-fields`, body, {headers});
        return lastValueFrom(response)
    }


}
