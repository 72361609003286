import {Injectable, OnInit} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export abstract class BaseBlocSettingsComponent implements OnInit {
    protected constructor() {
    }

    ngOnInit() {
        // Common initialization logic for all components
    }
}
