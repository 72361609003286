import {Component, Inject, Input, Injectable} from '@angular/core';
import {CalendarResourcePlanningService} from '@/angular-blocworx/components/fields/calendar-resource-planning/calendar-resource-planning.service';
import {FieldEditingService} from "@/angular-blocworx/components/fields/fieldEditing/field-editing.service";
import {BaseFieldsComponent} from "@/angular-blocworx/components/fields/BaseFieldsComponent";

@Component({
    selector: 'calendar-resource-planning-create-edit-popup',
    templateUrl: 'angular-blocworx/components/fields/calendar-resource-planning/templates/create-edit/popup.html',
    styleUrls: ['angular-blocworx/components/fields/calendar-resource-planning/calendar-resource-planning.component.scss'],
})

@Injectable()

export class CalendarResourcePlanningCreateEditPopup extends BaseFieldsComponent{

    @Input() fc;
    @Input() scanStation;
    calendarAreaFieldList: [];
    calendarAreaFieldList_byFieldId: [];
    calendarAreaDetailsBlocList: []
    fieldListById: any = {};
    externalJobs: any = {};
    selectedExtraDataLocalFieldId: string;
    selectedExtraDataRemoteFieldId: string
    selectedFilterDataLocalFieldId: string
    selectedFilterDataRemoteFieldId: string
    fcInitialFieldParameterArrays: Record<string, any[]> = {};

    constructor(
        @Inject(CalendarResourcePlanningService) private calendarService: CalendarResourcePlanningService,
        @Inject(FieldEditingService) private fieldEditingService: FieldEditingService) {
        super();
    }

    async ngOnInit(): Promise<void> {
        await this.initialiseFieldParametersFromValues()
    }

    async ngAfterViewInit(): Promise<void> {
        if(this.fc.initialFieldParams.calendarAreaDetailsBlocList.jobId == null || this.fc.initialFieldParams.calendarAreaDetailsBlocList.jobId == "") {

            let job = {
                "id" : this.scanStation.scanStationObj.jobID,
                "title" : ""
            }

            await this.setModuleToLoad('calendarAreaDetailsBlocList',job,true);
        }
    }

    /**
     * This code initialises all the field parameters so there is nothing left undefined in the html
     */
    async initialiseFieldParametersFromValues(): Promise<void> {
        this.scanStation.externalJobs = {};

        const defaultFieldParameterObject: object = this.calendarService.getInitialParams();

        this.fc.initialFieldParams = this.calendarService.initiateAllFieldParametersFromValues(this.fc.initialFieldParams, defaultFieldParameterObject);

        if (this.fc.initialFieldParams.calendarAreaDetailsBlocList.jobId != "") {
            let blocListRequest = await this.fieldEditingService.getBlocsFromModuleSelection('calendarAreaDetailsBlocList', this.fc.initialFieldParams, this.fc.currentJobId);
            if(blocListRequest.data != null) {
                this.calendarAreaDetailsBlocList = blocListRequest.data;
            }
        }

        if (this.fc.initialFieldParams.calendarAreaBlocDetails.stationId != "") {
            await this.getFieldsFromBlocId("calendarAreaFieldList", this.fc.initialFieldParams.calendarAreaBlocDetails.stationId);
        }

        if(this.fc.scanStationFieldsForRuleIdsToField == null) {
            this.fc.scanStationFieldsForRuleIdsToField = {};
        }

        if(this.fc.initialFieldParams['sendExtraDataMatchingFields'] != null) {
            this.fcInitialFieldParameterArrays['sendExtraDataMatchingFields'] = Object.values(this.fc.initialFieldParams['sendExtraDataMatchingFields']);
        }

        if(this.fc.initialFieldParams['filterDataLookUp'] != null) {
            this.fcInitialFieldParameterArrays['filterDataLookUp'] = Object.values(this.fc.initialFieldParams['filterDataLookUp']);
        }
    }

    async setModuleToLoad(moduleFieldParameter, job, secondInstanceOfDirective = false) {
        this.fc.initialFieldParams[moduleFieldParameter] = {
            jobId: job.id
        };
        let blocListRequest = await this.fieldEditingService.getBlocsFromModuleSelection(moduleFieldParameter, this.fc.initialFieldParams, this.fc.currentJobId);
        if(blocListRequest.data != null) {
            this[moduleFieldParameter] = blocListRequest.data;
        }

        if (secondInstanceOfDirective == false) {
            this.scanStation.externalJobs = {};
            this.scanStation.externalJobKeyWord = job.title
        } else {
            this.scanStation.externalJobsTwo = {};
            this.scanStation.externalJobKeyWordTwo = job.title
        }
    }

    convertObjectToArray(object:object): any[] {
        if (object != null) {
            return Object.values(object);
        }
    }

    addOrRemoveLocalAndRemoteMatchingFieldPairs(fieldParameter, action, localFieldId, remoteFieldId, index = null) {
        this.fc.initialFieldParams[fieldParameter] = this.calendarService.addOrRemoveLocalAndRemoteMatchingFieldPairs(this.fc.initialFieldParams[fieldParameter], action, localFieldId, remoteFieldId, index);
        console.log(this.fc.initialFieldParams[fieldParameter]);
        this.fcInitialFieldParameterArrays[fieldParameter] = Object.values(this.fc.initialFieldParams[fieldParameter]);
        console.log(this.fcInitialFieldParameterArrays[fieldParameter])
    }

    async getFieldsFromBlocId(parameterFieldList, blocId) {

        let fieldList = await this.fieldEditingService.getFieldsForBlocIdForFieldEditing(blocId);

        if(fieldList.scanStationFields != null) {
            this[parameterFieldList] = fieldList.scanStationFields;
        }

        this[parameterFieldList + '_byFieldId'] = this[parameterFieldList].reduce((acc, cur) => {
            acc[cur.id] = cur;
            return acc;
        }, {});

    }

    getFieldObjectFromFieldList(parameterFieldList: string, fieldlist: any[], idToSearchBy: string): any {

        if (this.fieldListById[parameterFieldList] == null) {
            this.fieldListById[parameterFieldList] = {};
        }

        let fieldObject = fieldlist.find(obj => obj.id === idToSearchBy);
        if (fieldObject) {
            this.fieldListById[parameterFieldList][`_${idToSearchBy}`] = fieldObject;
        }

        return true;
    }
}
