import {Component, Inject, Injectable, OnInit, ViewChild, ViewChildren, Input, ElementRef, QueryList} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {RequestService} from "angular-blocworx/components/configuration/services/request.service";

/**
 * Object that will be responsible to store all configuration's
 * data from the whole application
 *
 * @constructor
 */

@Injectable({
    providedIn: 'root'
})


@Component({"template":""})
export class Configuration implements OnInit {

    private version = '3.9.0';

    private apiUrl = '';
    private country = '';
    private flag = '';
    private domainWithTDL = '';
    private domain = window.location.hostname;

    private timestamp = Date.now();
    private subDomain = '';
    private wsHost = process.env.WH_HOST;
    private wsPort = process.env.WS_PORT;
    private pusherAppId = process.env.PUSHER_APP_ID;
    private pusherAppKey = process.env.PUSHER_APP_KEY;

    constructor(

    ) {

    }

    ngOnInit(): void {
        this.loadData();
    };


    public loadData  = () =>  {

        if (this.apiUrl === '' || this.domainWithTDL === '') {

            let parts = this.domain.split('.');
            let sub = parts[0];

            // setting the subdomain data
            this.subDomain = sub;

            if (this.domainWithTDL === '') {
                this.domainWithTDL = parts[1] + '.' + parts[2];
            }
            if (this.apiUrl === '') {
                this.apiUrl = window.location.protocol + '//' + sub + "." + this.domainWithTDL + "/api/";
            }
        }
    }
    public getEvervaultInboundRelayUrl = () => {

        let appSettingParsed = JSON.parse(localStorage.getItem('appSettings'));

        for (let setting in appSettingParsed.data) {
            if (appSettingParsed.data.hasOwnProperty(setting)) {

                if (appSettingParsed.data[setting].setting_key == 'evervault_url') {
                    if(appSettingParsed.data[setting].setting_value.includes('https') && appSettingParsed.data[setting].setting_value.includes('relay.evervault.com')) {
                        return appSettingParsed.data[setting].setting_value + '/api/';
                    }
                }
            }
        }

        return this.apiUrl;
    };

    // This will return is the version of the software
    public getVersion = function () {
        return this.version;
    }

    // This will return what is the domain
    public getDomain = function () {
        return this.domain;
    }

    // This will return what is base apiURL
    public getApiUrl = () => {
        if (!this.apiUrl || this.apiUrl == '') {
            this.loadData();
        }
        return this.apiUrl;
    }
    // This will return what is the subdomain
    public getSubDomain = function () {
        return this.subDomain;
    }

    // This will return what is the domain with TDL option
    public getDomainWithTDL = function () {
        return this.domainWithTDL;
    }

    // This will return a random value
    public getRandomValue = function () {
        return (Math.random() * (1.00 - 0.00))
    }

    // This will return the Ws Host Setting
    public getWSHost = function () {
        return this.wsHost;
    }

    // This will return the Ws Port
    public getWSPort = function () {
        return this.wsPort;
    }

    // This will return the Pusher App ID
    public getPusherAppId = function () {
        return this.pusherAppId;
    }

    // This will return the Pusher App Key
    public getPusherAppKey = function () {
        return this.pusherAppKey;
    }
}
