import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationStart } from '@angular/router';
import {CalendarResourcePlanningService} from "@/angular-blocworx/components/fields/calendar-resource-planning/calendar-resource-planning.service";
import * as moment from 'moment';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';


@Component({
    selector: 'show-counted-data-popup',
    templateUrl: 'angular-blocworx/components/fields/calendar-resource-planning/templates/show-counted-data-popup.component.html'
})

export class ShowCountedDataPopup {

    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<ShowCountedDataPopup>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        @Inject(MatSnackBar) private snackBar: MatSnackBar,
        @Inject(Router) private router: Router,
        @Inject(CalendarResourcePlanningService) private calendarService: CalendarResourcePlanningService,
        @Inject(BreakpointObserver) private breakpointObserver: BreakpointObserver

    ) {

    }


    callParentEditEntryPopUp(event, item, leftValue): void {
        if (this.data.editEntryPopUp) {
            this.closeDialog();
            this.data.editEntryPopUp(event, item, leftValue);
        }
    }
    closeDialog(): void {
        this.dialogRef.close();
    }

    ngOnInit() {
        console.log(this.data);

    }


}