import {HttpClient} from '@angular/common/http';
import {Configuration} from '../configuration/services/configuration.service';
import {RequestService} from '../configuration/services/request.service';
import {Inject} from '@angular/core';


export abstract class BaseBlocSettingsService {
    protected apiUrl: string;

    protected constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(Configuration) protected configuration: Configuration,
        @Inject(RequestService) protected request: RequestService
    ) {
        this.apiUrl = this.configuration.getApiUrl();
    }
}
