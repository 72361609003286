import { Injectable, Inject,  Renderer2 } from '@angular/core'
@Injectable({
    providedIn: 'root',
})

export class RuleService {

    constructor(@Inject(Renderer2) private renderer: Renderer2) { }

    /**
     * This will be doing some logic for clean the rule afterwards
     * by using event as main parameter.
     * @param event this is coming from the html function.
     * @param variableThatTriggerCleanAction this is the variable
     * that will contain a value, when this is clean, the value is false,
     * we should run only when this value is false.
     */
    public cleanBrokenRule = (event, variableThatTriggerCleanAction) => {

        // case that we cleaned the box with message, so after that we must clean the input
        // class rule-broken.
        if(variableThatTriggerCleanAction == false) {
            // we must have the event target id to be able to remove the error box class
            if (event?.target?.id) {
                this.renderer.removeClass(this.renderer.selectRootElement(`#${event.target.id}`), 'rule-broken');
            }
        }
    }

}
