import {Injectable, Inject} from '@angular/core';
import {Configuration} from "@/angular-blocworx/components/configuration/services/configuration.service";
import {RequestService} from "@/angular-blocworx/components/configuration/services/request.service";
import {HttpClient, HttpParams} from '@angular/common/http';

import {lastValueFrom} from 'rxjs';
import angular from "angular";

@Injectable({

    providedIn: 'root'

})

export class FieldEditingService {

    apiUrl: string;

    constructor(@Inject(HttpClient) private http: HttpClient,
                @Inject(Configuration) private configuration: Configuration,
                @Inject(RequestService) private request: RequestService) {

        this.apiUrl = this.configuration.getApiUrl();

    }
    public getBlocsFromModuleSelection = async (paramSetting, initialFieldParams, currentJobId) : Promise<any> => {

        let jobId = currentJobId;

        if (initialFieldParams[paramSetting] != null && initialFieldParams[paramSetting].jobId != null) {
            jobId = initialFieldParams[paramSetting].jobId;
        }

        let headers = this.request.getHeaders();
        const params = new HttpParams().set('jobID', jobId);

        let response = this.http.get(
            `${this.apiUrl}get-scan-stations`,
            {params, headers}
        );

        return lastValueFrom(response);

    }

    public getFieldsForBlocIdForFieldEditing = async (blocId) : Promise<any> => {

        let headers = this.request.getHeaders();
        const params = new HttpParams()
            .set('id', blocId)
            .set('source', "rule-query")

        let response = this.http.get(
            `${this.apiUrl}get-scan-station-information`,
            {params, headers}
        );

        return lastValueFrom(response);

    }

    public addOrRemoveLocalAndRemoteMatchingFieldPairs (localFieldMatchingFieldPairs, action, localField, remoteField, index = null) {

        if((localField == '' || typeof localField == 'undefined') && action == 'add') {
            alert('Please Select a local Field');
            return localFieldMatchingFieldPairs;
        }

        if((remoteField == '' || typeof remoteField == 'undefined') && action == 'add') {
            alert('Please Select a remote field it must match');
            return localFieldMatchingFieldPairs;
        }

        if( localFieldMatchingFieldPairs == null || localFieldMatchingFieldPairs == "") {
            localFieldMatchingFieldPairs = {};
        }

        if(action == 'add') {
            localFieldMatchingFieldPairs[Object.keys(localFieldMatchingFieldPairs).length] = {
                'localFieldId' : localField,
                'remoteFieldId' : remoteField
            };

        } else {
            delete localFieldMatchingFieldPairs[index];
            // build new object with fresh keys to keep it in sync
            let fieldListForLoop = localFieldMatchingFieldPairs;
            localFieldMatchingFieldPairs = {};
            let i: number = 0;
            angular.forEach(fieldListForLoop, function(value) {
                localFieldMatchingFieldPairs[i] = value;
                i++;
            });
        }

        return localFieldMatchingFieldPairs;

    }

    public addSingleFieldList = (fieldArray, fieldId = null) => {

        if (fieldId == null) {

            alert('Please Select a Field');
            return fieldArray;
        }


        if (fieldArray == null || typeof fieldArray == 'undefined' || (typeof fieldArray != 'undefined' && fieldArray.length == 0) || (typeof fieldArray === 'object' && Object.keys(fieldArray).length == 0)) {
            fieldArray = [];
        }

        // rebuild to an array if its an object
        if (typeof fieldArray === 'object' && Object.keys(fieldArray).length > 0) {
            let temporaryArray = [];

            angular.forEach(fieldArray, function (value) {
                temporaryArray.push(value);
            })

            fieldArray = temporaryArray;
        }

        fieldArray.push(fieldId);

        return fieldArray;
    }

    public removeFromFieldList = (fieldArray, index = null) => {

        if (index == null) {
            alert('Please Select a field');
            return fieldArray;
        }

        delete fieldArray[index];

        // build new object with fresh keys to keep it in sync
        let fieldListForLoop = fieldArray;
        fieldArray = [];
        let i = 0;

        angular.forEach(fieldListForLoop, function (value) {
            fieldArray[i] = value;
            i++;
        });
        return fieldArray;
    }



    validateCondition = function (localFieldId: string, condition: string, valueToCompare: string): void | Error {

        if (!localFieldId) {
            throw new Error('Local Field to check is not defined or empty');
        }

        if (!condition) {
            throw new Error('Condition is not defined or empty');
        }

        if (!valueToCompare) {
            throw new Error('Value to compare is not defined or empty');
        }

        if (condition !== 'equal' && condition !== 'notEqual' && !Number(valueToCompare)) {
            throw new Error('Strings must use condition Equal');
        }
    }

    /**
     * Method responsible for adding conditions
     * @param conditions array of conditions
     * @param localFieldId id of the local field
     * @param condition condition to be added
     * @param valueToCompare value to compare
     * @returns conditions array with new condition
     */
    addCondition = function (conditions: any, localFieldId: string, condition: string, valueToCompare: string): any {


        conditions[Object.keys(conditions).length] = {
            localFieldId,
            condition,
            valueToCompare
        };
        return conditions;
    }

    /**
     * Method responsible for removing conditions
     * @param conditions array of conditions
     * @param index index of condition to be removed
     * @returns conditions array without condition
     */
    removeCondition = function (conditions: any, index: number): any {

        delete conditions[index];

        // build new object with fresh keys to keep it in sync
        let fieldListForLoop = conditions;
        conditions = {};
        let i = 0;
        for (let key in fieldListForLoop) {
            conditions[i] = fieldListForLoop[key];
            i++;
        }
        return conditions;
    }


}