import {Injectable, Inject} from '@angular/core';
import {Configuration} from "@/angular-blocworx/components/configuration/services/configuration.service";
import {RequestService} from "@/angular-blocworx/components/configuration/services/request.service";
import {UpgradeModule, setAngularJSGlobal} from '@angular/upgrade/static';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class MathsFactoryBLCXAU {
    apiUrl: string;

    constructor(@Inject(HttpClient) private http: HttpClient,
                @Inject(Configuration) private configuration: Configuration,
                @Inject(RequestService) private request: RequestService) {
        this.configuration.ngOnInit();
        this.apiUrl = this.configuration.getApiUrl();
    }

    /**
     * This function calls the API to recalculate all the maths values in the bloc
     * @param stationId
     * @param dataToAdd
     * @returns {Promise<PushSubscription>}
     */
    public updateMathsValue = (stationId: any, dataToAdd: any, mathsTriggerFields: any) : Promise<any> => {

        let headers = this.request.getHeaders();

        let payload = {
            stationId: stationId,
            dataToAdd: dataToAdd,
            mathsTriggerFields: mathsTriggerFields
        };

        return this.http.post(
            `${this.apiUrl}update-maths-values`,
            payload,
            {headers}

        ).toPromise();
    }

}