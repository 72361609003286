import {Injectable, Inject} from '@angular/core';
import {Configuration} from "@/angular-blocworx/components/configuration/services/configuration.service";
import {RequestService} from "@/angular-blocworx/components/configuration/services/request.service";
import {HttpClient, HttpParams} from '@angular/common/http';
import {lastValueFrom} from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class VersionControlService {
    apiUrl: string;

    constructor(@Inject(HttpClient) private http: HttpClient,
                @Inject(Configuration) private configuration: Configuration,
                @Inject(RequestService) private request: RequestService) {

        this.apiUrl = this.configuration.getApiUrl();
    }

    /**
     * This gets the live domain from the .env file of the live site (not the .env file from this site)
     */
    public getLiveVersionDomain = async () : Promise<any> => {

        let headers = this.request.getHeaders();

        const params = new HttpParams()

        let response = this.http.get(
            `${this.apiUrl}get-live-version-domain`,
            {params, headers}
        );

        return lastValueFrom(response)

    }

    /**
     * This is the service that calls the Laravel back end to push no-code changes to live
     */
    public pushDevelopmentBuildToLiveBuild = async () : Promise<any> => {

        let headers = this.request.getHeaders();

        const body = {}

        const response = this.http.post(`${this.apiUrl}push-development-build-to-live-build`, body, {headers});

        return lastValueFrom(response)

    }


}