import {Inject, Injectable} from '@angular/core';
import * as angular from "angular";
import {BaseFieldService} from "@/angular-blocworx/components/fields/BaseFieldService";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Configuration} from "@/angular-blocworx/components/configuration/services/configuration.service";
import {RequestService} from "@/angular-blocworx/components/configuration/services/request.service";
import {lastValueFrom} from "rxjs";


@Injectable({
    providedIn: 'root'
})
export class CrossBlocService extends BaseFieldService {

    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(Configuration) protected configuration: Configuration,
        @Inject(RequestService) protected request: RequestService
    ) {
        super(http, configuration, request);
    }

    public triggerActions = (fieldIds, dataToAdd, sourceRecordId): Promise<any> => {
        const headers = this.request.getHeaders()

        const body = {
            fieldIds,
            dataToAdd,
            sourceRecordId,
        }
        const response = this.http.post(`${this.apiUrl}trigger-cross-bloc-data-actions`, body, {headers});
        return lastValueFrom(response)
    }


}