import {Configuration} from "@/angular-blocworx/components/configuration/services/configuration.service";
import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {RequestService} from "@/angular-blocworx/components/configuration/services/request.service";
import {lastValueFrom} from "rxjs";


@Injectable({
    providedIn: 'root'
})

/**
 * This service contains functions that will be possibly be used across the entire software.
 */

export abstract class BlocworxSharedService {
    protected apiUrl: string;
    protected evervaultEnabledApiUrl: string;

    protected constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(Configuration) protected configuration: Configuration,
        @Inject(RequestService) protected request: RequestService
    ) {
        this.configuration.ngOnInit();
        this.apiUrl = this.configuration.getApiUrl();
        this.evervaultEnabledApiUrl = this.configuration.getEvervaultInboundRelayUrl();
    }

    getFileByPath = async (path) : Promise<any> =>{

        let apiBase:string = this.evervaultEnabledApiUrl + 'get-file-by-path';

        let params = new HttpParams()
            .set('path', path);

        let headers = this.request.getHeaders();

        const response = this.http.get(apiBase, {params, headers, responseType: 'blob'});

        return lastValueFrom(response);
    }

}
