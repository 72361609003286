import {Component, Inject, OnInit, Input } from '@angular/core';
import {ScanStationService} from "@/angular-blocworx/components/scanStation/scan-station-service";

import * as angular from "angular";

@Component({
    selector: 'scan-station-component',
    templateUrl: 'angular-blocworx/components/scanStation/scan-station.html'
})

export class ScanStationComponent implements OnInit {


    @Input() scanStation;

    constructor(
        @Inject(ScanStationService) private scanStationService: ScanStationService,

    ) {
        this.scanStationService.setGetScanStationInformationComponent(this.getScanStationInformationFromComponent.bind(this));
        this.scanStationService.setInitialiseScanStationObjectPropertiesBeforeProcessedFields(this.initialiseScanStationObjectPropertiesBeforeProcessedFields.bind(this));
        this.scanStationService.setInitialiseScanStationObjectPropertiesAfterProcessedFields(this.initiliseScanStationObjectPropertiesAfterProcessedFields.bind(this));
    }

    getScanStationInformationFromComponent = async (id, queryType, reload = false) => {
        try {
            let data = await this.scanStationService.getScanStationInformation(id, this.scanStation.scanStationObj.stateName, reload);
            if (typeof (data.message) != 'undefined' && data.message == 'Not Allowed') {
                this.scanStation.hasScanStationPermission = false;
                return;
            } else {
                this.scanStation.hasScanStationPermission = true;
            }

            this.scanStation.scanStationFieldDesignOptions = [];
            this.scanStation.scanStationObj.stationDetails = data.stationDetails;

            if (this.scanStation.scanStationObj.stationDetails.parameters && this.scanStation.scanStationObj.stationDetails.parameters.length > 0) {

                for(let i = 0; i < this.scanStation.scanStationObj.stationDetails.parameters.length; i++) {
                    let stationParam = this.scanStation.scanStationObj.stationDetails.parameters[i];
                    if (stationParam.scan_station_parameter == 'email_alert_options') {
                        this.scanStation.scanStationObj.stationDetails[stationParam.scan_station_parameter] = JSON.parse(stationParam.parameter_value);
                        if (this.scanStation.scanStationObj.stationDetails[stationParam.scan_station_parameter].emailAddressParam != null) {
                            this.scanStation.emailAddressParam = this.scanStation.scanStationObj.stationDetails[stationParam.scan_station_parameter].emailAddressParam;
                        }

                        if (this.scanStation.scanStationObj.stationDetails[stationParam.scan_station_parameter].emailParam != null) {
                            this.scanStation.emailParamDefaultText = this.scanStation.scanStationObj.stationDetails[stationParam.scan_station_parameter].emailParam;
                        }
                    } else if (stationParam.scan_station_parameter == 'restrictDataByUsername') {
                        this.scanStation.scanStationObj.stationDetails[stationParam.scan_station_parameter] = (stationParam.parameter_value).replaceAll('"', '').split(',');
                    } else if (stationParam.scan_station_parameter == 'blocRedirect' && this.scanStation.stateName == 'bloc') {
                        let parameterValueUrl = stationParam.parameter_value;
                        let url = parameterValueUrl.replace(/\\/g, '');
                        url = url.replaceAll('"', '');
                        window.location = url;
                    } else {
                        this.scanStation.scanStationObj.stationDetails[stationParam.scan_station_parameter] = this.scanStation.isJson(stationParam.parameter_value)
                            ? JSON.parse(stationParam.parameter_value)
                            : stationParam.parameter_value;
                    }
                }

            }

            if (this.scanStation.scanStationObj.stationDetails.defaultNumberOfResults != null) {
                this.scanStation.resultLimit = this.scanStation.scanStationObj.stationDetails.defaultNumberOfResults;
            }

            if (queryType == 'add-new-field') {
                this.scanStation.scanStationObj.scanStationFields.push(data.scanStationFields[data.scanStationFields.length - 1]);
            } else if (queryType != 'non-fields-related') {
                this.scanStation.scanStationObj.scanStationFields = data.scanStationFields;
            }

            this.initialiseScanStationObjectPropertiesBeforeProcessedFields();

            await this.scanStation.processScanStationFields();
            await this.scanStation.processScanStationFieldsForResults()
            await this.scanStation.loadJsonDataFromUrl();

            this.scanStation.scanStationObj.scanStationFieldsReady = true;

            this.initiliseScanStationObjectPropertiesAfterProcessedFields(data);

            this.scanStation.checkIfLoadMore();


        } catch (e) {
            console.log(e)
        }
    };

    ngOnInit(): void {

    }

    initialiseScanStationObjectPropertiesBeforeProcessedFields = () => {
        this.scanStation.scanStationObj.showForm = this.scanStation.scanStationObj.stationDetails.hide_form != 1;
        this.scanStation.scanStationObj.scanStationFieldSlugs = [];
        this.scanStation.scanStationObj.scanStationFieldTypes = [];
        this.scanStation.scanStationObj.scanStationFieldNames = [];
        this.scanStation.scanStationObj.scanStationFieldCounterArray = [];
        this.scanStation.scanStationObj.scanStationHideOptions = [];
        this.scanStation.scanStationObj.scanStationTimePickerFields = [];
        this.scanStation.scanStationObj.scanStationDatePickerFields = [];
        this.scanStation.scanStationObj.childParentStation = false;
        this.scanStation.scanStationObj.scanStationMathsResultsFields = {};
        this.scanStation.scanStationObj.checkboxesWithDynamicDataFields = [];
        this.scanStation.scanStationObj.dropdownFilteredDataFields = [];
        this.scanStation.scanStationObj.fieldsToExcludeFromGetData = [];
        this.scanStation.scanStationObj.fieldsUsedForMergeField = {};
        this.scanStation.scanStationObj.autogenerateTriggerFields = {};
        this.scanStation.scanStationObj.calendarPrimaryFieldFormat = 'YYYY-MM-DD';
        this.scanStation.scanStationObj.scanStationFieldIDsToSlugs = {};
        this.scanStation.scanStationObj.scanStationFieldIdsToField = {};
        this.scanStation.scanStationObj.scanStationFieldSlugsToField = {};
        this.scanStation.scanStationObj.populateLatestDataFields = [];
        this.scanStation.scanStationObj.fieldsThatDontSaveData = [];
        this.scanStation.scanStationObj.crossBlocDataUpdateFieldsOnDataSubmit = [];
        this.scanStation.scanStationObj.crossBlocDataUpdateFieldsOnDataAfterDelete = [];
        this.scanStation.scanStationObj.crossBlocDataUpdateFieldsOnDataAfterResultsBoxRecordDeletion = [];
        this.scanStation.scanStationObj.mailAlertFieldsOnSubmit = [];
        this.scanStation.scanStationObj.mailAlertFieldsWhenEditing = [];
        this.scanStation.scanStationObj.blocworxGatewayFieldsOnSubmit = [];
        this.scanStation.scanStationObj.blocworxGatewayFieldsWhenEditing = [];
        this.scanStation.scanStationObj.dataToFormLatestDataFilters = {};
        this.scanStation.scanStationObj.fieldIdsThatChangeDates = [];
    }

    initiliseScanStationObjectPropertiesAfterProcessedFields = (data) => {
        this.scanStation.scanStationObj.scanStationFieldSlugs = angular.toJson(this.scanStation.scanStationObj.scanStationFieldSlugs);
        this.scanStation.scanStationObj.selectedSOPFiles = data.stationFiles.SOPFiles;
        this.scanStation.scanStationObj.selectedRolesForLoadingUsers = data.selectedRolesForLoadingUsers;
        this.scanStation.scanStationObj.stationUnits = data.stationUnits;
        this.scanStation.scanStationObj.stationName = data.stationName;
        this.scanStation.scanStationObj.requires_anti_static = data.stationRequiresAntiStatic;
        this.scanStation.scanStationObj.hide_from_reports = data.hideFromReports;
        this.scanStation.scanStationObj.useCustomisedLayout = data.useCustomisedLayout;
        this.scanStation.scanStationObj.previousButtonURL = data.previousButtonURL;
        this.scanStation.scanStationObj.nextButtonURL = data.nextButtonURL;
        this.scanStation.scanStationObj.linkedStationInstruction = data.linkedStationInstruction;
        this.scanStation.scanStationObj.scan_limit = data.scanLimit;
        this.scanStation.scanStationObj.scan_limit_time_logged = data.scanLimitTimeLogged;
        this.scanStation.scanStationObj.sections = data.sections;
        this.scanStation.scanStationObj.stationSectionID = data.sectionID;
        this.scanStation.scanStationObj.stationRevision = data.stationRevision;
        this.scanStation.scanStationObj.nextRevision = parseInt(this.scanStation.stationRevision) + 1;
        this.scanStation.scanStationObj.defaultFieldActionsList = data.defaultFieldActionsList;
        this.scanStation.scanStationObj.fullListOfActions = data.fullListOfActions;
    }

}