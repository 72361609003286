import {Component, Inject, OnInit, Input} from '@angular/core';


@Component({
    selector: 'calendar-resource-planning-create-edit-radio-button',
    templateUrl: 'angular-blocworx/components/fields/calendar-resource-planning/templates/create-edit/radio-button.html',
    styleUrls: ['angular-blocworx/components/fields/calendar-resource-planning/calendar-resource-planning.component.scss'],
})

export class CalendarResourcePlanningEditRadioButton implements OnInit {

    @Input() fc;
    @Input() scanStation;

    ngOnInit(): void {

    }

    setFieldPopUp(activeField) {
        this.fc.initialFieldParams={};
        this.scanStation.activeField = activeField;
    }
}