import {Inject, Injectable} from '@angular/core';
import {Configuration} from "@/angular-blocworx/components/configuration/services/configuration.service";
import {RequestService} from "@/angular-blocworx/components/configuration/services/request.service";
import {HttpClient} from '@angular/common/http';
import {lastValueFrom} from 'rxjs';
import {BaseBlocSettingsService} from "@/angular-blocworx/components/BlocSettings/BaseBlocSettingsService"

@Injectable({
    providedIn: 'root'
})


export class ChangeBlocModuleService extends BaseBlocSettingsService {
    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(Configuration) protected configuration: Configuration,
        @Inject(RequestService) protected request: RequestService
    ) {
        super(http, configuration, request);
    }

    public getListOfJobs = (): Promise<any> => {
        const headers = this.request.getHeaders()

        const response = this.http.get(`${this.apiUrl}job`, {headers});
        return lastValueFrom(response)

    }

    public changeBlocModule = (scanStationID: string, jobID: string): Promise<any> => {
        const headers = this.request.getHeaders()
        const body = {
            jobID,
            scanStationID
        }
        const response = this.http.post(`${this.apiUrl}change-module`, body, {headers});
        return lastValueFrom(response)
    }

}
