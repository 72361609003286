import {Component, Inject, Input, OnInit} from '@angular/core';
import { ChangeBlocModuleService } from "@/angular-blocworx/components/BlocSettings/changeBlocModule/change-bloc-module.service"
import {BaseBlocSettingsComponent} from "@/angular-blocworx/components/BlocSettings/BaseBlocSettingsComponent"

@Component({
    selector: 'change-bloc-module',
    templateUrl: 'angular-blocworx/components/BlocSettings/changeBlocModule/change-bloc-module.html',
    styleUrls: ['angular-blocworx/components/BlocSettings/changeBlocModule/change-bloc-module.scss']
})

/**
 * Component that allows the user to move the bloc to another module
 * @class
 * @extends BaseBlocSettingsComponent
 * @implements OnInit
 * @throws {Error}
 * @exports ChangeBlocModuleModule
 *
 */
export class ChangeBlocModule extends BaseBlocSettingsComponent implements OnInit {

    @Input() scanStation;
    options: any[] = [];
    selectedJobID: string = '';

    /**
     * Constructor of the component that changes the module of the bloc
     * @param changeBlocModuleService
     */
    constructor(@Inject(ChangeBlocModuleService) private changeBlocModuleService: ChangeBlocModuleService) {
        super();
    }

    /**
     * Executes the logic of the component that changes the selected job ID
     * @param value
     */
    onSelectionChange(value: string): void {
        this.selectedJobID = value;
    }

    /**
     * Executes the logic of the component that changes the module of the bloc
     * @return {Promise<void>}
     * @throws {Error}
     */
    async changeBlocModule(): Promise<void> {

        try {
            if (this.selectedJobID === '') {
                alert('Please select a module before continuing')
                return
            }
            const response = await this.changeBlocModuleService.changeBlocModule(this.scanStation.id, this.selectedJobID)

            if(response.success === true) {
                alert('Module changed successfully')
            }

        } catch (e) {
            alert('Error changing module')
            console.log(e)
        }
    }

    /**
     * Executes the initialization logic of the component that gets the list of jobs
     * @return {Promise<void>}
     * @throws {Error}
     */
    async ngOnInit(): Promise<void> {

        try {
            const listOfModules = await this.changeBlocModuleService.getListOfJobs()
            this.options = listOfModules.data
        } catch (e) {
            console.log(e)
        }
    }
}
