import {Injectable, OnInit} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export abstract class BaseFieldsComponent implements OnInit {
    protected constructor() {
    }

    ngOnInit() {
        // Common initialization logic for all fields components
    }

    canEdit(main) {
        if (main.userRoles.includes('can-edit-data-in-form') || main.userRoles.includes('admin-front-end') || main.userRoles.includes('admin')) {
            return true;
        } else {
            return false;
        }
    }
}
