import {Component, OnInit, Input, Inject} from '@angular/core';
import {Subject} from 'rxjs';
import {ResultsBoxService} from "@/angular-blocworx/components/fields/results-box/results-box.service";
import {CrossBlocService} from "@/angular-blocworx/components/fields/cross-bloc/cross-bloc.service";
import {ScanStationService} from "@/angular-blocworx/components/scanStation/scan-station-service";
import {BlocworxSharedService} from "@/angular-blocworx/components/shared/blocworx-shared.service";
import {Configuration} from "@/angular-blocworx/components/configuration/services/configuration.service";
import * as angular from "angular";
import {DomSanitizer} from '@angular/platform-browser';
import { isEqual } from 'lodash';
import {GridService} from "@/angular-blocworx/components/grid/grid.service";
import { Subscription } from 'rxjs';


@Component({
    selector: 'results-box-in-form',
    templateUrl: 'angular-blocworx/components/fields/results-box/templates/in-form.html',
    styleUrls: ['angular-blocworx/components/fields/results-box/results-box.component.scss'],
})

export class ResultsBoxInForm implements OnInit {

    @Input() field;
    @Input() scanStation;
    @Input() fc;
    subDomain: string = '';
    loadingData: Array<any> = [];
    fileUrl: string = '';
    entries: { [key: string]: any[] } = {};
    tableColumns: Array<any> = [];
    columnOrders: Array<any> = [];
    resultsBoxDataFilteringData: Array<any> = [];
    resultsBoxRawData: any = {};
    private dataSubscription: Subscription;
    private columnOrdersSubscription: Subscription;
    private tableColumnsSubscription: Subscription;


    constructor(
        @Inject(ResultsBoxService) private resultsBoxService: ResultsBoxService,
        @Inject(ScanStationService) private scanStationService: ScanStationService,
        @Inject(BlocworxSharedService) private blocworxSharedService: BlocworxSharedService,
        @Inject(Configuration) private configuration: Configuration,
        @Inject(DomSanitizer) private sanitizer: DomSanitizer,
        @Inject(CrossBlocService) private crossBlocService: CrossBlocService,
        @Inject(GridService) private gridService: GridService
    ) {
        this.resultsBoxService.setTriggerResultsBoxFromFieldActions(this.triggerResultsBoxFromFieldActions.bind(this));
        this.resultsBoxService.setTriggerResultsBoxViaForcedRefreshList(this.triggerResultsBoxViaForcedRefreshList.bind(this));
        this.resultsBoxService.setTriggerClearResultsBox(this.clearResultsBox.bind(this));

    }

    async triggerResultsBoxFromFieldActions(triggerFieldSlug) {

        // loop through this.scanStation.resultsBoxFieldsThatTrigger[triggerFieldSlug] and trigger the results box for each field
        for (let i = 0; i < this.scanStation.resultsBoxFieldsThatTrigger[triggerFieldSlug].length; i++) {
			
			console.log(triggerFieldSlug);
			console.log(this.scanStation.resultsBoxFieldsThatTrigger[triggerFieldSlug][i])
			
            let resultsBoxField = this.scanStation.scanStationObj.scanStationFieldSlugsToField[this.scanStation.resultsBoxFieldsThatTrigger[triggerFieldSlug][i]];
			
            let legacyValueToSend = this.getLegacyValueToSend(resultsBoxField);

            let parseResults = this.scanStation.scanStationObj.parseResultsBoxArray[resultsBoxField.field_slug];
            if(parseResults != null) {
                let dataFilteringData = this.resultsBoxService.getDataFilteringDataForResultsBox(parseResults, this.scanStation.id, legacyValueToSend, this.scanStation.scanStationObj.scanStationFieldIdsToField, this.scanStation.scanStationObj.dataToAdd)


                // check if dataFilteringData and this.resultsBoxDataFilteringData[resultsBoxField.field_slug] are identical objects and return if they are
                if (isEqual(dataFilteringData, this.resultsBoxDataFilteringData[resultsBoxField.field_slug]) && this.scanStation.scanStationObj.scanStationFieldSlugsToField[triggerFieldSlug].forceResultsBoxRefreshing != 1) {
                    return;
                }

                this.resultsBoxDataFilteringData[resultsBoxField.field_slug] = dataFilteringData;

                await this.prepareResultsBoxSettings(resultsBoxField, resultsBoxField.field_slug);
                await this.loadResultsBoxData(dataFilteringData, resultsBoxField, legacyValueToSend);
            }
        }
    }

    async triggerResultsBoxViaForcedRefreshList(fieldsToRefreshAfterCompletion) {

        // convert fieldsToRefreshAfterCompletion to an array if it's not already
        if (!Array.isArray(fieldsToRefreshAfterCompletion)) {
            fieldsToRefreshAfterCompletion = Object.values(fieldsToRefreshAfterCompletion);
        }

        // loop through fieldsToRefreshAfterCompletion and trigger the results box for each field
        for (let i = 0; i < fieldsToRefreshAfterCompletion.length; i++) {
            let resultsBoxField = this.scanStation.scanStationObj.scanStationFieldIdsToField[fieldsToRefreshAfterCompletion[i]];
            let legacyValueToSend = this.getLegacyValueToSend(resultsBoxField);

            let parseResults = this.scanStation.scanStationObj.parseResultsBoxArray[resultsBoxField.field_slug];
            if(parseResults != null) {
                let dataFilteringData = this.resultsBoxService.getDataFilteringDataForResultsBox(parseResults, this.scanStation.id, legacyValueToSend, this.scanStation.scanStationObj.scanStationFieldIdsToField, this.scanStation.scanStationObj.dataToAdd)

                this.resultsBoxDataFilteringData[resultsBoxField.field_slug] = dataFilteringData;

                await this.prepareResultsBoxSettings(resultsBoxField, resultsBoxField.field_slug);
                await this.loadResultsBoxData(dataFilteringData, resultsBoxField, legacyValueToSend);
            }
        }
    }

    async ngOnInit() {

        this.dataSubscription = this.resultsBoxService.entries$.subscribe(data => {
            if (data) {
                // If data is valid, assign it to this.entries
                this.entries = data;
            } else {

                this.entries = {};
            }
        });

        this.columnOrdersSubscription = this.resultsBoxService.columnOrders$.subscribe(columnOrders => {
            if (columnOrders) {
                // If data is valid, assign it to this.entries
                this.columnOrders = columnOrders;
            } else {

                this.columnOrders = [];
            }
        });

        this.tableColumnsSubscription = this.resultsBoxService.tableColumns$.subscribe(tableColumns => {
            if (tableColumns) {
                // If data is valid, assign it to this.entries
                this.tableColumns = tableColumns;
            } else {

                this.tableColumns = [];
            }
        });

        this.subDomain = this.configuration.getSubDomain();
    }

    prepareResultsBoxSettings = async (field, fieldSlug)=> {
        try {

            if (this.scanStation.scanStationObj.stateName == 'edit-bloc') {
                return true;
            }

            if (this.scanStation.scanStationObj.parseResultsBoxArray[this.field.field_slug] != null) {
                return;
            }

            if (typeof (field.resultsBoxReverseOrder) == 'undefined') {
                field.resultsBoxReverseOrder = 0;
            }

            this.scanStation.scanStationObj.parseResultsBoxArray[fieldSlug] = {
                'scanStationID': field.resultsBoxStationAndFields.stationId,
                'resultsBoxRemoteFieldId': field.resultsBoxStationAndFields.remoteFieldId,
                'resultsBoxLocalFieldId': field.resultsBoxStationAndFields.localFieldId,
                'sortByFieldId': field.resultsBoxStationAndFields.sortByFieldId,
                'resultsBoxReverseOption': field.resultsBoxReverseOrder,
                'localFieldMatchingFieldPairs': field.localFieldMatchingFieldPairs
            }

            if (this.scanStation.resultsBoxScanStationData == null) {
                this.scanStation.resultsBoxScanStationData = [];
            }
            let data: any = {};

            if (this.scanStation.resultsBoxScanStationData['_' + this.scanStation.scanStationObj.parseResultsBoxArray[fieldSlug].scanStationID] == null) {
                data = await this.scanStationService.getScanStationDetails(this.scanStation.scanStationObj.parseResultsBoxArray[fieldSlug].scanStationID);
                this.scanStation.resultsBoxScanStationData['_' + this.scanStation.scanStationObj.parseResultsBoxArray[fieldSlug].scanStationID] = data;
            } else {
                data = this.scanStation.resultsBoxScanStationData['_' + this.scanStation.scanStationObj.parseResultsBoxArray[fieldSlug].scanStationID];
            }

            let stationDetails = data.data[0];

            this.scanStation.scanStationObj.parseResultsBoxArray[fieldSlug].jobID = stationDetails.job_id;

            if (stationDetails.independant_station_slug != null) {
                this.scanStation.scanStationObj.parseResultsBoxArray[fieldSlug].scanStationIndependantStationSlug = stationDetails.independant_station_slug;
            }

            let fields: any = {};

            if (this.scanStation.resultsBoxScanStationFieldSavedData == null) {
                this.scanStation.resultsBoxScanStationFieldSavedData = [];
            }

            if (this.scanStation.resultsBoxScanStationFieldSavedData['_' + this.scanStation.scanStationObj.parseResultsBoxArray[fieldSlug].scanStationID] == null) {
                fields = await this.resultsBoxService.getScanStationFields(this.scanStation.scanStationObj.parseResultsBoxArray[fieldSlug].scanStationID);
                this.scanStation.resultsBoxScanStationFieldSavedData['_' + this.scanStation.scanStationObj.parseResultsBoxArray[fieldSlug].scanStationID] = fields;
            } else {
                fields = this.scanStation.resultsBoxScanStationFieldSavedData['_' + this.scanStation.scanStationObj.parseResultsBoxArray[fieldSlug].scanStationID];
            }

            this.scanStation.scanStationObj.parseResultsBoxFieldsArray[fieldSlug] = fields.data;


        } catch (e) {
            console.log(e);
        }
    }

    loadResultsBoxData =  async (dataFilteringData, resultsBoxField, data) => {
        try {
            await this.getFilteredData(dataFilteringData, 'fieldId');
            await this.buildResultsDataTable(resultsBoxField);
            await this.scanStation.getRules('no-event', resultsBoxField.id, resultsBoxField.special_field_key, this.scanStation.scanStationObj.dataToAdd[resultsBoxField.field_slug], resultsBoxField.fieldIndex, resultsBoxField.field_slug);

        } catch (e) {
            console.log(e);
        }
    }

    async ngAfterViewInit() {

        await this.prepareResultsBoxSettings(this.field, this.field.field_slug);

        let legacyValueToSend = this.getLegacyValueToSend(this.field);
        let parseResults = await this.scanStation.scanStationObj.parseResultsBoxArray[this.field.field_slug];
        let dataFilteringData = this.resultsBoxService.getDataFilteringDataForResultsBox(parseResults, this.scanStation.id, legacyValueToSend, this.scanStation.scanStationObj.scanStationFieldIdsToField, this.scanStation.scanStationObj.dataToAdd)
        this.resultsBoxDataFilteringData[this.field.field_slug] = dataFilteringData;
        await this.loadResultsBoxData(dataFilteringData, this.field, legacyValueToSend);

    }

    getLegacyValueToSend = (reultsBoxField) => {
        let arrayData = this.scanStation.scanStationObj.scanStationFields;
        let size = arrayData.length
        let legacyValueToSend = '';

        if (this.field.localFieldMatchingFieldPairs != null && Object.keys(reultsBoxField.localFieldMatchingFieldPairs).length > 0) {
            return legacyValueToSend
        } else {
            for (let index = 0; index < size; index++) {
                let value = arrayData[index];
                if (value.id == reultsBoxField.resultsBoxStationAndFields.localFieldId) {
                    return this.scanStation.scanStationObj.dataToAdd[value.field_slug];
                }
            }
        }

        return legacyValueToSend;
    }


    canShowResultsBoxField = (resultsBoxField, field) => {

        // we are using the old method to check for hidden results box field, where its set using a hidden field parameter in the source bloc
        if (field.onlyShowFieldArray == null || (Object.keys(field.onlyShowFieldArray).length == 0)) {
            if (this.resultsBoxRawData.fieldHiddenOptions != null
                && this.resultsBoxRawData.fieldHiddenOptions[resultsBoxField.field_slug] != null
                && this.resultsBoxRawData.fieldHiddenOptions[resultsBoxField.field_slug].includes('results_box')) {
                return false;
            } else {
                return true;
            }
        } else {

            // this is the newer method for hiding/showing fields, where the hiding and showing can be set in the results box field instead of the source bloc

            let returnValue = false;
            let onlyShowFieldArray = [];

            // Check if field.onlyShowFieldArray is an array and use it directly,
            // otherwise, extract values if it's an object
            if (Array.isArray(field.onlyShowFieldArray)) {
                onlyShowFieldArray = field.onlyShowFieldArray;
            } else if (typeof field.onlyShowFieldArray === 'object' && field.onlyShowFieldArray !== null) {
                onlyShowFieldArray = Object.values(field.onlyShowFieldArray);
            }

            for (let i = 0; i < onlyShowFieldArray.length; i++) {
                if (resultsBoxField.id == parseFloat(onlyShowFieldArray[i])) {
                    returnValue = true;
                }
                if (resultsBoxField.id == onlyShowFieldArray[i]) {
                    returnValue = true;
                }
            }

            return returnValue;

        }
    }

    buildResultsDataTable = async (field) => {
        try {
            if (this.scanStation.scanStationObj.stateName == 'edit-bloc') {
                return true;
            }

            let resultBoxData = {th: [], td: [], sortBy: ''}
            let resultBoxCollection = [];

            // getting field index on domIndexes
            this.scanStation.domIndexes[field.field_slug] = field.fieldIndex;

            resultBoxCollection = this.scanStation.scanStationObj.parseResultsBoxFieldsArray[field.field_slug];

            // setting the value to an empty record
            // TODO this may not be even necessary but it gives us more control over whats happening in the data.
            // We cannot use an empty value because when it's empty rules are not getting triggered.
            this.scanStation.scanStationObj.dataToAdd[field.field_slug] = 'Results Box Data';

            // This is the filter step, we will only be having those columns
            let onlyShowSpecificColumns = field.onlyShowFieldArray == undefined ? [] : Object.values(field.onlyShowFieldArray);

            // building th and td of the table
            if (onlyShowSpecificColumns.length) {
                // onlyShowSpecificColumns = has the order of the columns
                onlyShowSpecificColumns.forEach((fieldId) => {
                    // this will loop though the result box collection
                    resultBoxCollection.forEach((resultBoxField) => {
                        if (resultBoxField.id == fieldId) {

                            // getting the sort by parameter
                            if (field.resultsBoxStationAndFields.sortByFieldId != undefined
                                && field.resultsBoxStationAndFields.sortByFieldId == resultBoxField.id) {
                                resultBoxData.sortBy = resultBoxField.field_slug;
                            }

                            // setting the header
                            resultBoxData.th = [...resultBoxData.th, resultBoxField]

                            // loading the body
                            let tdObject = this.loadTd(field.field_slug, resultBoxField);
                            resultBoxData.td = tdObject.map(function callback(val, index, array) {
                                return this.length <= 0 ? val : Object.assign(this[index], val)
                            }, resultBoxData.td);


                        }
                    });
                })
            } else {
                resultBoxCollection.forEach((resultBoxField) => {

                    // getting the sort by parameter
                    if (field.resultsBoxStationAndFields.sortByFieldId != undefined
                        && field.resultsBoxStationAndFields.resultBoxData != undefined
                        && field.resultsBoxStationAndFields.resultBoxData.sortByFieldId != undefined
                        && field.resultsBoxStationAndFields.resultBoxData.sortByFieldId == resultBoxField.id) {
                        resultBoxData.sortBy = resultBoxField.field_slug;
                    }

                    // setting the header
                    resultBoxData.th = [...resultBoxData.th, resultBoxField]

                    // loading the body
                    let tdObject = this.loadTd(field.field_slug, resultBoxField);
                    resultBoxData.td = tdObject.map(function callback(val, index, array) {
                        return this.length <= 0 ? val : Object.assign(this[index], val)
                    }, resultBoxData.td);

                });
            }

            this.entries[field.field_slug] = Object.entries(resultBoxData.td)
                .map(([key, value]) => ({ key, value }));

            this.resultsBoxService.updateEntries(this.entries);

            this.tableColumns[field.field_slug] = Object.entries(resultBoxData.th)
                .map(([key, value]) => ({ key, value }));

            this.resultsBoxService.updateTableColumns( this.tableColumns);

            this.columnOrders[field.field_slug] = this.tableColumns[field.field_slug].map(col => col.value.field_slug);

            this.resultsBoxService.updateColumnOrders(this.columnOrders);


        } catch (e) {
            console.log(e)
        }
    }

    loadTd =  (fieldSlug, resultBoxField) => {
        // Getting the result box data
        let resultBoxData = this.resultsBoxRawData != undefined
            ? Object.values(this.resultsBoxRawData)
                : [];

        let data = [];
        let recordIds = [];

        // this will be creating the data object so later on we can export the data
        resultBoxData.forEach((result) => {
            let valueToSet = result[resultBoxField.field_slug];
            data = [...data, valueToSet];
            recordIds.push(result['cartolytics_entry_row_id']);

        });

        // this is the build of the td itself
        let returnedData = data.map(function callback(currentValue, index, array) {
            let data = {};
            data[this] = {
                value: currentValue,
                field_type: resultBoxField.field_type,
                job_id: resultBoxField.job_id,
                scan_station_id: resultBoxField.scan_station_id,
                record_id: recordIds[index],
            };
            return data
        }, resultBoxField.field_slug);

        return returnedData;
    }

    getResultsBoxColourFromData = (valueBasedCellColours, value) => {

        let colourToReturn = '';

        if (!Array.isArray(valueBasedCellColours) && valueBasedCellColours != null) {
            valueBasedCellColours = Object.values(valueBasedCellColours);
        }

        if (valueBasedCellColours != null) {

            for (let i = 0; i < valueBasedCellColours.length; i++) {
                if (valueBasedCellColours[i].value == value) {
                    colourToReturn = valueBasedCellColours[i].colour;
                }
            }

        }

        return colourToReturn;
    }

    getFilteredData = async (dataFilteringData, fieldIdOrFieldSlug) => {
        try {

            if (this.scanStation.scanStationObj.stateName == 'edit-bloc') {
                return true;
            }

            let data = await this.resultsBoxService.getDataForDataFiltering(dataFilteringData, fieldIdOrFieldSlug);
            this.resultsBoxRawData = data.data;

            for (let rowKey in this.resultsBoxRawData) {
                if (isNaN(parseInt(rowKey))) {
                    delete this.resultsBoxRawData[rowKey];
                }
            }
        } catch (e) {
            console.log(e);
        } finally {
        }
    }

    isFile = (fieldType, data) => {
        return fieldType == 'file' && data != 'N/A'
    }

    /**
     * This mount the filepath of a field element.
     *
     * @param path
     * @param fileName
     * @param targetOption
     */
    getFileByPath = async (resultBoxData, subDomain: string, targetOption: string = 'new-tab') => {
        try {
            let fileName = resultBoxData.value;
            let path = `job-data/${subDomain}/${resultBoxData.job_id}/scan-station-uploads/${resultBoxData.scan_station_id}/${fileName}`;
            let fileArray = fileName.replace(" ", "_");

            this.loadingData[fileArray] = true;
            let data = await this.blocworxSharedService.getFileByPath(path);

            this.loadingData[fileArray] = false;
            this.openFileLocally(fileName, data, targetOption);


        } catch (e) {
            console.log(e);
        }

    }

    showMultiButtonValueOnly = (resultsBoxFieldType, editableResultsBoxFields, fieldSlug, rowKey) => {
        return resultsBoxFieldType == 'multi-button' && (editableResultsBoxFields == null || (editableResultsBoxFields[fieldSlug] != rowKey));
    }

    showNonEditableNormalFieldsForResultsBox = (resultsBoxDataFieldType, resultBoxDataValue, editableResultsBoxFields, fieldSlug, rowKey) => {
        return this.isHtml(resultsBoxDataFieldType, resultBoxDataValue) && (editableResultsBoxFields == null || (editableResultsBoxFields != null && editableResultsBoxFields[fieldSlug] != rowKey));
    }

    showEditableNormalFieldsForResultsBox = (resultsBoxDataFieldType, resultBoxDataValue, editableResultsBoxFields, fieldSlug, rowKey) => {
        return (resultsBoxDataFieldType == 'multi-button' || this.isHtml(resultsBoxDataFieldType, resultBoxDataValue)) && (editableResultsBoxFields != null && editableResultsBoxFields[fieldSlug] == rowKey);
    }

    isHtml = function (fieldType, data) {
        return fieldType != 'file' && fieldType != 'digital-signature' && fieldType != 'multi-button' && fieldType != 'image-url-field' || data == 'N/A'
    }

    isDigitalSignature = function (fieldType, data, scanStation) {
        return (
            fieldType == 'digital-signature'
        )
    }

    loading = (fileName: string) => {
        let fileArray = fileName.replace(" ", "_");
        return this.loadingData[fileArray];
    }

    openFileLocally = function (fileName, data, targetOption) {

        try {
            let browserAvailableExtensions = [
                'AVI', 'CSS', 'GIF', 'HTML', 'HTM', 'JPG', 'JPEG', 'MID',
                'MIDI', 'MP3', 'MPG', 'MPEG', 'MOV', 'QT', 'PDF', 'PNG', 'RAM',
                'RAR', 'TIFF', 'TXT', 'WAV', 'ZIP'
            ];

            let fileAlreadyDownloaded = false;

            let ext = fileName.substr(fileName.lastIndexOf('.') + 1);
            ext = ext.toUpperCase();
            let url = window.URL || window.webkitURL;
            this.fileUrl = url.createObjectURL(data);
            let a = document.createElement("a");

            // we have to choose the download option if the file does not open in a browser tab
            if (targetOption == 'download' || !browserAvailableExtensions.includes(ext)) {
                a.href = this.fileUrl;
                a.download = fileName;
                a.target = '_self';
                document.body.appendChild(a);
                a.click();
                fileAlreadyDownloaded = true;
            }

            if (targetOption == 'new-tab' && !fileAlreadyDownloaded) {
                a.href = this.fileUrl;
                let tab = window.open();
                tab.location.href = this.fileUrl;
                a.download = fileName;
                a.target = '_blank';
                document.body.appendChild(a);
            }

        } catch (e) {
            console.log(e);
        } finally {

        }

    }

    toTrusted(htmlString: string) {
        return this.sanitizer.bypassSecurityTrustHtml(htmlString);
    }

    deleteRecordFromResultsBox = async (resultsBoxFieldRecords, resultsBoxField) => {
        await this.scanStationService.deleteData(resultsBoxFieldRecords[Object.keys(resultsBoxFieldRecords)[0]].record_id);

        resultsBoxField = this.resultsBoxService.addCurrentResultsBoxFieldToRefreshList(resultsBoxField);

        await this.triggerResultsBoxViaForcedRefreshList(resultsBoxField.fieldsToRefreshAfterCompletion);

        await this.scanStation.updateDropDownsRadiosAutoCompletesForAllFields(resultsBoxField);

        if (this.scanStation.scanStationObj.crossBlocDataUpdateFieldsOnDataAfterResultsBoxRecordDeletion.length > 0) {

            // build the array of field Ids to apply this to, hidden ones should not be applied. This gives control over the user to decide if they
            // want to apply the action or not

            let crossBlocDataUpdateFieldsOnDataAfterResultsBoxRecordDeletion = [];

            let fields = this.scanStation.scanStationObj.crossBlocDataUpdateFieldsOnDataAfterResultsBoxRecordDeletion;

            for (let index = 0; index < fields.length; index++) {
                let fieldPair = fields[index];
                let isVisibleFromShowCondition = this.gridService.scanStationFormValidate(this.scanStation, this.scanStation.scanStationObj.scanStationFieldIdsToField[fieldPair.crossBlocFieldId]);
                if (fieldPair.resultsBoxFieldId == resultsBoxField.id && isVisibleFromShowCondition) {
                    crossBlocDataUpdateFieldsOnDataAfterResultsBoxRecordDeletion.push(fieldPair.crossBlocFieldId);
                }
            }

            // we don't need an await as we are not doing anything with this response

            this.crossBlocService.triggerActions(crossBlocDataUpdateFieldsOnDataAfterResultsBoxRecordDeletion, this.scanStation.scanStationObj.dataToAdd, false);

        }
    }

    /**
     * This function determines if we are showing the edit header in the results box
     * We basically dont if we are in View/Show More Mode
     * @param field
     */
    showResultsBoxEditHeader = (field) => {
        return (field.allowValueEdits == 1 && !this.scanStation.isViewMode());
    }

    /**
     * This function determines if we are showing the delete header in the results box
     * We basically dont if we are in View/Show More Mode     * @param field
     */
    showResultsBoxDeleteHeader = (field) => {
        return (field.showDeleteIcon == 1 && !this.scanStation.isViewMode());
    }

    /**
     * This function determines if we are showing the results box edit icon
     * @param field
     * @param rowKey
     */
    showResultsBoxEditIcon = (field, rowKey) => {
        return (field.allowValueEdits == 1 && (this.scanStation.editableResultsBoxFields == null || (this.scanStation.editableResultsBoxFields != null && this.scanStation.editableResultsBoxFields[field.field_slug] != rowKey) && !this.scanStation.isViewMode()));
    }

    /**
     * This redirects the user to a url added by the person who created the results box field
     * @param rowKey
     * @param data
     * @param viewIconUrl
     * @param fieldSlug
     */
    goToIconUrlFromResultsBox = (rowKey, viewIconUrl, fieldSlug) => {

        const newObject = {};

        for (const key in this.entries[fieldSlug][rowKey].value) {
            newObject[key] = this.entries[fieldSlug][rowKey].value[key].value;
        }

        let jsonString = JSON.stringify(newObject);

        window.location.href = viewIconUrl + '?urlFieldSlugData=' + jsonString;
    }

    triggerShowMoreResultsBoxPopUp = async (rowKey, resultsBoxFieldSlug, resultsBoxFieldRecords) => {

        if(this.scanStation.scanStationObj.scanStationFieldSlugsToField[resultsBoxFieldSlug].viewIconPopUp == 1) {
            this.scanStation.gridPopUpObject = {};

            this.scanStation.gridPopUpObject.gridPopUpTriggerSource =  'showing-results-box-record';
            this.scanStation.gridPopUpObject.sourceField = this.scanStation.scanStationObj.scanStationFieldSlugsToField[resultsBoxFieldSlug];
            this.scanStation.gridPopUpObject.popUpOrEmbedded = 'popup';

            let firstKey = Object.keys(resultsBoxFieldRecords)[0];

            await this.scanStation.openRemoteScanStationPopUp(this.scanStation.scanStationObj.scanStationFieldSlugsToField[resultsBoxFieldSlug].resultsBoxStationAndFields.stationId, resultsBoxFieldRecords[firstKey].record_id, this.scanStation.gridPopUpObject.gridPopUpTriggerSource, null);
        }

    }

    /**
     * This initiates the record inside the results box to an editable state
     * @param rowKey
     * @param resultsBoxFieldSlug
     * @param resultsBoxFieldRecords
     */
    makeResultsBoxRecordEditable = async (rowKey, resultsBoxFieldSlug, resultsBoxFieldRecords) => {

        if(this.scanStation.scanStationObj.scanStationFieldSlugsToField[resultsBoxFieldSlug].openBlocToEditRecord == 1) {
            this.scanStation.gridPopUpObject = {};

            this.scanStation.gridPopUpObject.gridPopUpTriggerSource =  'editing-results-box-record';
            this.scanStation.gridPopUpObject.sourceField = this.scanStation.scanStationObj.scanStationFieldSlugsToField[resultsBoxFieldSlug];
            this.scanStation.gridPopUpObject.popUpOrEmbedded = 'popup';

            let firstKey = Object.keys(resultsBoxFieldRecords)[0];

            await this.scanStation.openRemoteScanStationPopUp(this.scanStation.scanStationObj.scanStationFieldSlugsToField[resultsBoxFieldSlug].resultsBoxStationAndFields.stationId, resultsBoxFieldRecords[firstKey].record_id, this.scanStation.gridPopUpObject.gridPopUpTriggerSource, null);
        } else {
            // back up the original result incase we decide to cancel
            this.scanStation.resultsBoxFieldRevert = angular.copy(resultsBoxFieldRecords);

            if (this.scanStation.editableResultsBoxFields == null) {
                this.scanStation.editableResultsBoxFields = [];
            }

            this.scanStation.editableResultsBoxFields[resultsBoxFieldSlug] = rowKey;
        }

    }

    /**
     * This function determines if we are showing the save icon in the results box
     * @param field
     * @param rowKey
     */
    showResultsBoxSaveIcon = (field, rowKey) => {
        if (this.scanStation.editableResultsBoxFields != null && this.scanStation.editableResultsBoxFields[field.field_slug] != null) {
            return (field.allowValueEdits == 1 && this.scanStation.editableResultsBoxFields[field.field_slug] == rowKey);
        }
    }

    /**
     * This function triggers the field actions for the bloc that the results
     * box is loading data from, it optionally saves the data too
     * @param e
     * @param resultsBoxField
     */
    triggerFieldActionsForSingleRecordInResultsBox = async (e, resultsBoxFieldData, field, rowKey, saveData) => {
        this.scanStation.prompt = false;

        // we don't want to trigger things as we're typing, just after tab or enter or save
        if (e.keyCode === 13 || e.keyCode === 9 || e.type === 'click') {

            let data = await this.resultsBoxService.triggerFieldActionsForSingleRecordInResultsBox(resultsBoxFieldData, saveData, field);

            let redWarningReject = false;

            for (let key in data.data) {

                if (typeof data.data[key].value === 'string' && data.data[key].value.includes("blocworx_red_warning_error")) {
                    this.scanStation.prompt = data.data[key].value.replace('[blocworx_red_warning_error]', '');
                    await this.scanStation.triggerPrompt(false);
                    data.data[key].value = '';
                    redWarningReject = true;
                }
            }

            // update the data so the user can see the output, but it hasnt saved yet
            if (saveData == false || redWarningReject == true) {
                //TODO sort this out
                //vm.scanStationObj.parseResultsBoxFieldsArray[field.field_slug].td[rowKey] = data.data;
            } else {
                // here we have saved so we can refresh things
                this.scanStation.resultsBoxFieldRevert = null;
                this.scanStation.editableResultsBoxFields[field.field_slug] = null;

                field = await this.resultsBoxService.addCurrentResultsBoxFieldToRefreshList(field);

                await this.triggerResultsBoxViaForcedRefreshList(field.fieldsToRefreshAfterCompletion);
                await this.scanStation.updateDropDownsRadiosAutoCompletesForAllFields(field);
            }

        }
    }

    /**
     * This cancels the editing of a record in a results box without saving it
     * It reverts the values and also cancels the array key that we are editing
     * @param fieldSlug
     * @param rowKey
     */
    cancelResultsBoxEditing = (fieldSlug, rowKey) => {
        this.scanStation.editableResultsBoxFields[fieldSlug] = null;
        this.scanStation.scanStationObj.parseResultsBoxFieldsArray[fieldSlug].td[rowKey] = this.scanStation.resultsBoxFieldRevert;
    }

    /**
     * This functions determines if we are showing results box delete icon
     * @param field
     */
    showResultsBoxDeleteIcon = (field) => {
        return (field.showDeleteIcon == 1 && !this.scanStation.isViewMode());
    }

     clearResultsBox = (fieldSlug) => {

        this.entries[fieldSlug] = [];
        this.resultsBoxService.updateEntries(this.entries);

        this.columnOrders[fieldSlug] = [];
        this.resultsBoxService.updateColumnOrders(this.columnOrders);

        this.tableColumns[fieldSlug] = [];
        this.resultsBoxService.updateTableColumns(this.tableColumns);

         this.resultsBoxDataFilteringData[fieldSlug] = [];

    }


}
